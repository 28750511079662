/**
 * @generated SignedSource<<98ac50cc537e4d1dda97f53ae91351bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MedicarePlanType = "MEDICARE_ADVANTAGE_PART_D_PLAN" | "MEDICARE_ADVANTAGE_PLAN" | "MEDICARE_PART_D_PLAN" | "MEDICARE_SELECT_PLAN" | "MEDICARE_SUPPLEMENT_PLAN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type coveragePageLayoutFragment$data = {
  readonly medicareProfile: {
    readonly medicareBeneficiaryId: string | null;
  } | null;
  readonly supplementalCoverageHistory: {
    readonly currentCoverage: {
      readonly drugCoverage: {
        readonly plan?: {
          readonly medicarePlanType: MedicarePlanType;
        };
      } | null;
      readonly medicalCoverage: {
        readonly medicareSupplementPlan?: {
          readonly medicarePlanType: MedicarePlanType;
        };
        readonly plan?: {
          readonly medicarePlanType: MedicarePlanType;
        };
      } | null;
    } | null;
  } | null;
  readonly " $fragmentType": "coveragePageLayoutFragment";
};
export type coveragePageLayoutFragment$key = {
  readonly " $data"?: coveragePageLayoutFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"coveragePageLayoutFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "medicarePlanType",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "AMedicareAdvantagePartDPlan",
  "kind": "LinkedField",
  "name": "plan",
  "plural": false,
  "selections": (v0/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "coveragePageLayoutFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SupplementalCoverageHistory",
      "kind": "LinkedField",
      "name": "supplementalCoverageHistory",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SupplementalCoverage",
          "kind": "LinkedField",
          "name": "currentCoverage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "drugCoverage",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": (v1/*: any*/),
                      "action": "NONE",
                      "path": "supplementalCoverageHistory.currentCoverage.drugCoverage.plan"
                    }
                  ],
                  "type": "MedicareAdvantagePartDPolicy",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "concreteType": "APartDPlan",
                        "kind": "LinkedField",
                        "name": "plan",
                        "plural": false,
                        "selections": (v0/*: any*/),
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "supplementalCoverageHistory.currentCoverage.drugCoverage.plan"
                    }
                  ],
                  "type": "MedicarePartDPolicy",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "medicalCoverage",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": (v1/*: any*/),
                      "action": "NONE",
                      "path": "supplementalCoverageHistory.currentCoverage.medicalCoverage.plan"
                    }
                  ],
                  "type": "MedicareAdvantagePartDPolicy",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "concreteType": "AMedicareAdvantagePlan",
                        "kind": "LinkedField",
                        "name": "plan",
                        "plural": false,
                        "selections": (v0/*: any*/),
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "supplementalCoverageHistory.currentCoverage.medicalCoverage.plan"
                    }
                  ],
                  "type": "MedicareAdvantagePolicy",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "concreteType": "AMedicareSupplementPlan",
                        "kind": "LinkedField",
                        "name": "medicareSupplementPlan",
                        "plural": false,
                        "selections": (v0/*: any*/),
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "supplementalCoverageHistory.currentCoverage.medicalCoverage.medicareSupplementPlan"
                    }
                  ],
                  "type": "MedicareSupplementPolicy",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MedicareProfile",
      "kind": "LinkedField",
      "name": "medicareProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "medicareBeneficiaryId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "c19979478beaf9b234765ca8d0c93f19";

export default node;
