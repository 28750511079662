/**
 * @generated SignedSource<<8f91eea1524524157e5af8c98d98367d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpsertMemberIdMutation$variables = {
  personId: string;
  policyId: string;
  policyNumber: string;
};
export type UpsertMemberIdMutation$data = {
  readonly upsertCarrierPolicyId: {
    readonly person: {
      readonly supplementalCoverageHistory: {
        readonly currentCoverage: {
          readonly medicalCoverage: {
            readonly id?: string;
            readonly memberId?: string | null;
          };
        };
      };
    };
  };
};
export type UpsertMemberIdMutation = {
  response: UpsertMemberIdMutation$data;
  variables: UpsertMemberIdMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "policyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "policyNumber"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "carrierPolicyId",
        "variableName": "policyNumber"
      },
      {
        "kind": "Variable",
        "name": "personId",
        "variableName": "personId"
      },
      {
        "kind": "Variable",
        "name": "policyId",
        "variableName": "policyId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "memberId",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "MedicareAdvantagePolicy",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "MedicareAdvantagePartDPolicy",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertMemberIdMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertCarrierPolicyIdPayload",
        "kind": "LinkedField",
        "name": "upsertCarrierPolicyId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": {
                  "alias": null,
                  "args": null,
                  "concreteType": "SupplementalCoverageHistory",
                  "kind": "LinkedField",
                  "name": "supplementalCoverageHistory",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "concreteType": "SupplementalCoverage",
                        "kind": "LinkedField",
                        "name": "currentCoverage",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "medicalCoverage",
                              "plural": false,
                              "selections": [
                                (v4/*: any*/),
                                (v5/*: any*/)
                              ],
                              "storageKey": null
                            },
                            "action": "THROW",
                            "path": "upsertCarrierPolicyId.person.supplementalCoverageHistory.currentCoverage.medicalCoverage"
                          }
                        ],
                        "storageKey": null
                      },
                      "action": "THROW",
                      "path": "upsertCarrierPolicyId.person.supplementalCoverageHistory.currentCoverage"
                    }
                  ],
                  "storageKey": null
                },
                "action": "THROW",
                "path": "upsertCarrierPolicyId.person.supplementalCoverageHistory"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertMemberIdMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertCarrierPolicyIdPayload",
        "kind": "LinkedField",
        "name": "upsertCarrierPolicyId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SupplementalCoverageHistory",
                "kind": "LinkedField",
                "name": "supplementalCoverageHistory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SupplementalCoverage",
                    "kind": "LinkedField",
                    "name": "currentCoverage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "medicalCoverage",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "type": "MedicareSupplementPolicy",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fadf5a8fb4474b4c90556cf8b186ee7e",
    "id": null,
    "metadata": {},
    "name": "UpsertMemberIdMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertMemberIdMutation(\n  $personId: ID!\n  $policyId: ID!\n  $policyNumber: String!\n) {\n  upsertCarrierPolicyId(input: {personId: $personId, policyId: $policyId, carrierPolicyId: $policyNumber}) {\n    person {\n      supplementalCoverageHistory {\n        currentCoverage {\n          medicalCoverage {\n            __typename\n            ... on MedicareAdvantagePolicy {\n              id\n              memberId\n            }\n            ... on MedicareAdvantagePartDPolicy {\n              id\n              memberId\n            }\n            ... on MedicareSupplementPolicy {\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fcfc24887c05c016f4fc0e85ce69530f";

export default node;
