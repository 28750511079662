/**
 * @generated SignedSource<<6e96a8de904d6b7f4c8734dbcbcb4b8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddOverTheCounterCartItemsInput = {
  items: ReadonlyArray<AddOverTheCounterCartItemInput>;
  personId: string;
};
export type AddOverTheCounterCartItemInput = {
  itemId: string;
  quantity: number;
};
export type AddCartItemsMutation$variables = {
  input: AddOverTheCounterCartItemsInput;
};
export type AddCartItemsMutation$data = {
  readonly addOtcCartItems: {
    readonly person: {
      readonly overTheCounterCartItems: ReadonlyArray<{
        readonly item: {
          readonly catalog: {
            readonly id: string;
          };
          readonly id: string;
          readonly imageUrl: string | null;
          readonly name: string;
          readonly price: number;
        };
        readonly quantity: number;
      }>;
    };
  };
};
export type AddCartItemsMutation = {
  response: AddCartItemsMutation$data;
  variables: AddCartItemsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "OverTheCounterCartItem",
  "kind": "LinkedField",
  "name": "overTheCounterCartItems",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterItem",
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OverTheCounterCatalog",
          "kind": "LinkedField",
          "name": "catalog",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCartItemsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddOverTheCounterCartItemsPayload",
        "kind": "LinkedField",
        "name": "addOtcCartItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddCartItemsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddOverTheCounterCartItemsPayload",
        "kind": "LinkedField",
        "name": "addOtcCartItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "524f171444dcd403d1b138fcc64177aa",
    "id": null,
    "metadata": {},
    "name": "AddCartItemsMutation",
    "operationKind": "mutation",
    "text": "mutation AddCartItemsMutation(\n  $input: AddOverTheCounterCartItemsInput!\n) {\n  addOtcCartItems(input: $input) {\n    person {\n      overTheCounterCartItems {\n        item {\n          id\n          name\n          price\n          imageUrl\n          catalog {\n            id\n          }\n        }\n        quantity\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "92b414a80efd7386662b98dd7f542c52";

export default node;
