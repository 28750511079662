/**
 * @generated SignedSource<<474ab25261c59fafabb72fdac92e60c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useDrugSearch_DrugDosageGroupsQuery$variables = {
  after?: string | null;
  first?: number | null;
  id: string;
};
export type useDrugSearch_DrugDosageGroupsQuery$data = {
  readonly prescriptionDrugType: {
    readonly id: string;
    readonly prescriptionDrugGroups: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly displayName: string;
          readonly dosageName: string;
          readonly id: string;
          readonly rxcui: any;
        };
      }>;
    };
  } | null;
};
export type useDrugSearch_DrugDosageGroupsQuery = {
  response: useDrugSearch_DrugDosageGroupsQuery$data;
  variables: useDrugSearch_DrugDosageGroupsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "PrescriptionDrugType",
    "kind": "LinkedField",
    "name": "prescriptionDrugType",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          }
        ],
        "concreteType": "PrescriptionDrugTypeToPrescriptionDrugGroupConnection",
        "kind": "LinkedField",
        "name": "prescriptionDrugGroups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PrescriptionDrugTypeToPrescriptionDrugGroupEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PrescriptionDrugGroup",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rxcui",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dosageName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useDrugSearch_DrugDosageGroupsQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useDrugSearch_DrugDosageGroupsQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "05a51772e6c34e3da491b8c8ae8295e4",
    "id": null,
    "metadata": {},
    "name": "useDrugSearch_DrugDosageGroupsQuery",
    "operationKind": "query",
    "text": "query useDrugSearch_DrugDosageGroupsQuery(\n  $id: ID!\n  $first: Int\n  $after: String\n) {\n  prescriptionDrugType(id: $id) {\n    id\n    prescriptionDrugGroups(first: $first, after: $after) {\n      edges {\n        node {\n          id\n          rxcui\n          displayName\n          dosageName\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "854f096d6cad988908423fb9ef647992";

export default node;
