/**
 * @generated SignedSource<<b75381cc52cc4622576e13d7e27038ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type originalMedicareWalletCardFragment$data = {
  readonly medicareBeneficiaryId: string;
  readonly partAEffectiveDate: string | null;
  readonly partBEffectiveDate: string | null;
  readonly " $fragmentType": "originalMedicareWalletCardFragment";
} | null;
export type originalMedicareWalletCardFragment$key = {
  readonly " $data"?: originalMedicareWalletCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"originalMedicareWalletCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "originalMedicareWalletCardFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "medicareBeneficiaryId",
        "storageKey": null
      },
      "action": "NONE",
      "path": "medicareBeneficiaryId"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partAEffectiveDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partBEffectiveDate",
      "storageKey": null
    }
  ],
  "type": "MedicareProfile",
  "abstractKey": null
};

(node as any).hash = "450d888cdfe428cd273f4d9dceb43760";

export default node;
