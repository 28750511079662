/**
 * @generated SignedSource<<1f01a0da2d18ee4d391ad46e02e9af73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type countyForm_countiesQuery$variables = {
  zipCode: string;
};
export type countyForm_countiesQuery$data = {
  readonly counties: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly countyName: string;
        readonly fipsCountyCode: any;
      };
    }>;
  };
};
export type countyForm_countiesQuery = {
  response: countyForm_countiesQuery$data;
  variables: countyForm_countiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "zipCode"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "zipCode",
        "variableName": "zipCode"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countyName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fipsCountyCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "countyForm_countiesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CountyConnection",
        "kind": "LinkedField",
        "name": "counties",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CountyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ACounty",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "countyForm_countiesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CountyConnection",
        "kind": "LinkedField",
        "name": "counties",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CountyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ACounty",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a9c39825d6b49731228530957a4b8e0c",
    "id": null,
    "metadata": {},
    "name": "countyForm_countiesQuery",
    "operationKind": "query",
    "text": "query countyForm_countiesQuery(\n  $zipCode: String!\n) {\n  counties(filter: {zipCode: $zipCode}) {\n    edges {\n      node {\n        countyName\n        fipsCountyCode\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "11be69b492680442adef8213d655cdff";

export default node;
