/**
 * @generated SignedSource<<e4b2436755cd7f98b2b09a50ec5f9ec0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type orderTrackingWidgetFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"orderProgressOrderFragment" | "orderSummaryOrderFragment">;
  readonly " $fragmentType": "orderTrackingWidgetFragment";
};
export type orderTrackingWidgetFragment$key = {
  readonly " $data"?: orderTrackingWidgetFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"orderTrackingWidgetFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "orderTrackingWidgetFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "orderProgressOrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "orderSummaryOrderFragment"
    }
  ],
  "type": "OverTheCounterOrder",
  "abstractKey": null
};

(node as any).hash = "244b6eff808bbc6ef21ecf71029b6b41";

export default node;
