/**
 * @generated SignedSource<<41b7c5d2af09057f3fb5403b2b035276>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sharedMedicareCardFragment$data = {
  readonly legalFirstName: string | null;
  readonly legalLastName: string | null;
  readonly medicareProfile: {
    readonly medicareBeneficiaryId: string;
    readonly partAEffectiveDate: string | null;
    readonly partBEffectiveDate: string | null;
  };
  readonly " $fragmentType": "sharedMedicareCardFragment";
} | null;
export type sharedMedicareCardFragment$key = {
  readonly " $data"?: sharedMedicareCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"sharedMedicareCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sharedMedicareCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalLastName",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "MedicareProfile",
        "kind": "LinkedField",
        "name": "medicareProfile",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "medicareBeneficiaryId",
              "storageKey": null
            },
            "action": "NONE",
            "path": "medicareProfile.medicareBeneficiaryId"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "partAEffectiveDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "partBEffectiveDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "NONE",
      "path": "medicareProfile"
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "496a6a61e2f167de41f365aea0bc2069";

export default node;
