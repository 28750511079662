/**
 * @generated SignedSource<<790c4e8935f10230f89ea3a32b7b8f49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCartInfoFragment$data = {
  readonly id: string;
  readonly overTheCounterCartItems: ReadonlyArray<{
    readonly item: {
      readonly catalog: {
        readonly id: string;
      };
      readonly id: string;
      readonly price: number;
    };
    readonly quantity: number;
  }>;
  readonly resolvedOtcCatalog: {
    readonly id: string;
  } | null;
  readonly supplementalCoverageHistory: {
    readonly currentCoverage: {
      readonly medicalCoverage: {
        readonly plan?: {
          readonly overTheCounterBenefit: {
            readonly dollarAmount: number;
          } | null;
        };
      } | null;
    } | null;
  } | null;
  readonly " $fragmentType": "useCartInfoFragment";
};
export type useCartInfoFragment$key = {
  readonly " $data"?: useCartInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCartInfoFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OverTheCounterBenefit",
    "kind": "LinkedField",
    "name": "overTheCounterBenefit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dollarAmount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCartInfoFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterCatalog",
      "kind": "LinkedField",
      "name": "resolvedOtcCatalog",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterCartItem",
      "kind": "LinkedField",
      "name": "overTheCounterCartItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OverTheCounterItem",
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OverTheCounterCatalog",
              "kind": "LinkedField",
              "name": "catalog",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SupplementalCoverageHistory",
      "kind": "LinkedField",
      "name": "supplementalCoverageHistory",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SupplementalCoverage",
          "kind": "LinkedField",
          "name": "currentCoverage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "medicalCoverage",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AMedicareAdvantagePartDPlan",
                      "kind": "LinkedField",
                      "name": "plan",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "MedicareAdvantagePartDPolicy",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AMedicareAdvantagePlan",
                      "kind": "LinkedField",
                      "name": "plan",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "MedicareAdvantagePolicy",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "7446c09551efc5abfe7eee2362606198";

export default node;
