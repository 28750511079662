/**
 * @generated SignedSource<<9e871d50c9162209461e3d1615ca020f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type medicareInfoFormFragment$data = {
  readonly id: string;
  readonly legalFirstName: string | null;
  readonly legalLastName: string | null;
  readonly medicareProfile: {
    readonly medicareBeneficiaryId: string;
    readonly partAEffectiveDate: string | null;
    readonly partBEffectiveDate: string | null;
  } | null;
  readonly " $fragmentType": "medicareInfoFormFragment";
};
export type medicareInfoFormFragment$key = {
  readonly " $data"?: medicareInfoFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"medicareInfoFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "medicareInfoFormFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalLastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MedicareProfile",
      "kind": "LinkedField",
      "name": "medicareProfile",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "medicareBeneficiaryId",
            "storageKey": null
          },
          "action": "NONE",
          "path": "medicareProfile.medicareBeneficiaryId"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "partAEffectiveDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "partBEffectiveDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "b0236a6539ab34bd4c05402058eaa19f";

export default node;
