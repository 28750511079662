/**
 * @generated SignedSource<<ab57e5dbb4055e91faa23796cde77679>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OverTheCounterCatalogCollectionSortField = "name" | "numOtcItems" | "storeRank" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type OverTheCounterCatalogCollectionFilter = {
  storeRank?: NullableFilter | null;
};
export type NullableFilter = {
  isNull?: boolean | null;
};
export type OverTheCounterCatalogCollectionSort = {
  direction: SortDirection;
  field: OverTheCounterCatalogCollectionSortField;
};
export type otcStoreShoppingCollectionsRefetchQuery$variables = {
  after?: string | null;
  filter?: OverTheCounterCatalogCollectionFilter | null;
  first?: number | null;
  id: string;
  sort?: ReadonlyArray<OverTheCounterCatalogCollectionSort> | null;
};
export type otcStoreShoppingCollectionsRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"otcStoreShoppingCollectionsFragment">;
  } | null;
};
export type otcStoreShoppingCollectionsRefetchQuery = {
  response: otcStoreShoppingCollectionsRefetchQuery$data;
  variables: otcStoreShoppingCollectionsRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": {
    "storeRank": {
      "isNull": false
    }
  },
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": [
    {
      "direction": "ASC",
      "field": "storeRank"
    }
  ],
  "kind": "LocalArgument",
  "name": "sort"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "otcStoreShoppingCollectionsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "kind": "FragmentSpread",
            "name": "otcStoreShoppingCollectionsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "otcStoreShoppingCollectionsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "OverTheCounterCatalogCollectionConnection",
                "kind": "LinkedField",
                "name": "collections",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OverTheCounterCatalogCollectionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OverTheCounterCatalogCollection",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "handle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "storeRank",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 4
                              },
                              {
                                "kind": "Literal",
                                "name": "sort",
                                "value": {
                                  "direction": "ASC",
                                  "field": "rank"
                                }
                              }
                            ],
                            "concreteType": "OverTheCounterItemConnection",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OverTheCounterItemEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OverTheCounterItem",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v9/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "price",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "description",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "imageUrl",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "items(first:4,sort:{\"direction\":\"ASC\",\"field\":\"rank\"})"
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": [
                  "sort",
                  "filter"
                ],
                "handle": "connection",
                "key": "otcStoreShoppingCollectionsFragment_collections",
                "kind": "LinkedHandle",
                "name": "collections"
              }
            ],
            "type": "OverTheCounterCatalog",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4ddcf8ee2ea2cf1e3f6fe82a5b7eee43",
    "id": null,
    "metadata": {},
    "name": "otcStoreShoppingCollectionsRefetchQuery",
    "operationKind": "query",
    "text": "query otcStoreShoppingCollectionsRefetchQuery(\n  $after: String\n  $filter: OverTheCounterCatalogCollectionFilter = {storeRank: {isNull: false}}\n  $first: Int = 10\n  $sort: [OverTheCounterCatalogCollectionSort!] = [{field: storeRank, direction: ASC}]\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...otcStoreShoppingCollectionsFragment_3JsJJ3\n    id\n  }\n}\n\nfragment itemFragment on OverTheCounterItem {\n  id\n  name\n  description\n  price\n  imageUrl\n}\n\nfragment otcStoreShoppingCollectionsFragment_3JsJJ3 on OverTheCounterCatalog {\n  collections(first: $first, after: $after, sort: $sort, filter: $filter) {\n    edges {\n      node {\n        id\n        name\n        handle\n        storeRank\n        items(first: 4, sort: {field: rank, direction: ASC}) {\n          edges {\n            node {\n              id\n              name\n              price\n              ...itemFragment\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "d6025c749e605b1ceb0c745d6bdff5a2";

export default node;
