/**
 * @generated SignedSource<<881a80ace89c3c124a4dfb8ef8e84d9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MedicarePlanType = "MEDICARE_ADVANTAGE_PART_D_PLAN" | "MEDICARE_ADVANTAGE_PLAN" | "MEDICARE_PART_D_PLAN" | "MEDICARE_SELECT_PLAN" | "MEDICARE_SUPPLEMENT_PLAN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type medicareAdvantageWalletCardFragment$data = {
  readonly currentCoverage: {
    readonly medicalCoverage: {
      readonly memberId?: string | null;
      readonly membershipStartDate?: string | null;
      readonly plan?: {
        readonly medicarePlanType: MedicarePlanType;
        readonly organizationMarketingName: string;
        readonly planName: string;
      };
    };
  };
  readonly " $fragmentType": "medicareAdvantageWalletCardFragment";
} | null;
export type medicareAdvantageWalletCardFragment$key = {
  readonly " $data"?: medicareAdvantageWalletCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"medicareAdvantageWalletCardFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "membershipStartDate",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memberId",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "medicarePlanType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "planName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "organizationMarketingName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "medicareAdvantageWalletCardFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "SupplementalCoverage",
        "kind": "LinkedField",
        "name": "currentCoverage",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "medicalCoverage",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "concreteType": "AMedicareAdvantagePlan",
                        "kind": "LinkedField",
                        "name": "plan",
                        "plural": false,
                        "selections": (v2/*: any*/),
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "currentCoverage.medicalCoverage.plan"
                    }
                  ],
                  "type": "MedicareAdvantagePolicy",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "concreteType": "AMedicareAdvantagePartDPlan",
                        "kind": "LinkedField",
                        "name": "plan",
                        "plural": false,
                        "selections": (v2/*: any*/),
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "currentCoverage.medicalCoverage.plan"
                    }
                  ],
                  "type": "MedicareAdvantagePartDPolicy",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            "action": "NONE",
            "path": "currentCoverage.medicalCoverage"
          }
        ],
        "storageKey": null
      },
      "action": "NONE",
      "path": "currentCoverage"
    }
  ],
  "type": "SupplementalCoverageHistory",
  "abstractKey": null
};
})();

(node as any).hash = "398f11de466f922cd7fb73481a8b1c7e";

export default node;
