/**
 * @generated SignedSource<<67457ee7728ff25aaca66a8b32476606>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Frequency = "ANNUALLY" | "EVERY_THREE_YEARS" | "EVERY_TWO_YEARS" | "MONTHLY" | "QUARTERLY" | "TWICE_A_YEAR" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type orderDetailsOrderFragment$data = {
  readonly deliveredAt: string | null;
  readonly deliveryAddress: {
    readonly city: string;
    readonly state: string;
    readonly streetLineOne: string;
    readonly streetLineTwo: string | null;
    readonly zip: string;
  };
  readonly id: string;
  readonly items: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly item: {
          readonly description: string | null;
          readonly name: string;
          readonly price: number;
        };
        readonly quantity: number;
      };
    }> | null;
  };
  readonly placedAt: string;
  readonly policyMedicarePlan: {
    readonly organizationMarketingName?: string;
    readonly overTheCounterBenefit?: {
      readonly benefitFrequency: Frequency;
      readonly dollarAmount: number;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"orderProgressOrderFragment" | "orderSummaryOrderFragment" | "orderTrackingWidgetFragment">;
  readonly " $fragmentType": "orderDetailsOrderFragment";
};
export type orderDetailsOrderFragment$key = {
  readonly " $data"?: orderDetailsOrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"orderDetailsOrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "organizationMarketingName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "OverTheCounterBenefit",
    "kind": "LinkedField",
    "name": "overTheCounterBenefit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "benefitFrequency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dollarAmount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "orderDetailsOrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deliveredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeliveryAddress",
      "kind": "LinkedField",
      "name": "deliveryAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetLineOne",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetLineTwo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zip",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 50
        }
      ],
      "concreteType": "OverTheCounterOrderItemConnection",
      "kind": "LinkedField",
      "name": "items",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OverTheCounterOrderItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OverTheCounterOrderItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OverTheCounterItem",
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "price",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "items(first:50)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "policyMedicarePlan",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "AMedicareAdvantagePartDPlan",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "AMedicareAdvantagePlan",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "orderSummaryOrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "orderProgressOrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "orderTrackingWidgetFragment"
    }
  ],
  "type": "OverTheCounterOrder",
  "abstractKey": null
};
})();

(node as any).hash = "14002af7c7c2932e40996ff50cd40f55";

export default node;
