/**
 * @generated SignedSource<<c5a3280719b866c4f96f451f2258cdc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type itemFragment$data = {
  readonly description: string | null;
  readonly id: string;
  readonly imageUrl: string | null;
  readonly name: string;
  readonly price: number;
  readonly " $fragmentType": "itemFragment";
};
export type itemFragment$key = {
  readonly " $data"?: itemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"itemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "itemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    }
  ],
  "type": "OverTheCounterItem",
  "abstractKey": null
};

(node as any).hash = "af46d0e7b9e1a60ef298378e378236d8";

export default node;
