/**
 * @generated SignedSource<<8f460f29d057399f9572927fcfa8b712>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type cartTotalSummaryFragment$data = {
  readonly id: string;
  readonly overTheCounterCartItems: ReadonlyArray<{
    readonly item: {
      readonly catalog: {
        readonly id: string;
      };
      readonly id: string;
    };
  }>;
  readonly resolvedOtcCatalog: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"cartItemFragment" | "useCartInfoFragment" | "useOtcShoppingStateFragment">;
  readonly " $fragmentType": "cartTotalSummaryFragment";
};
export type cartTotalSummaryFragment$key = {
  readonly " $data"?: cartTotalSummaryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"cartTotalSummaryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "cartTotalSummaryFragment",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "cartItemFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCartInfoFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterCatalog",
      "kind": "LinkedField",
      "name": "resolvedOtcCatalog",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterCartItem",
      "kind": "LinkedField",
      "name": "overTheCounterCartItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OverTheCounterItem",
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "OverTheCounterCatalog",
              "kind": "LinkedField",
              "name": "catalog",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOtcShoppingStateFragment"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "7724db9f003b7676dd4e226d5152a7fb";

export default node;
