/**
 * @generated SignedSource<<6693cb9812f659aeaf49c79469a0e83f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateShippingAddressMutation$variables = {
  city: string;
  personId: string;
  state: string;
  streetLineOne: string;
  streetLineTwo?: string | null;
  zip: string;
};
export type UpdateShippingAddressMutation$data = {
  readonly updatePerson: {
    readonly person: {
      readonly id: string;
      readonly mailingAddress: {
        readonly city: string | null;
        readonly state: string | null;
        readonly streetLineOne: string | null;
        readonly streetLineTwo: string | null;
        readonly zip: string | null;
      } | null;
    };
  } | null;
};
export type UpdateShippingAddressMutation = {
  response: UpdateShippingAddressMutation$data;
  variables: UpdateShippingAddressMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "city"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "personId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "streetLineOne"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "streetLineTwo"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "zip"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "city",
                "variableName": "city"
              },
              {
                "kind": "Variable",
                "name": "state",
                "variableName": "state"
              },
              {
                "kind": "Variable",
                "name": "streetLineOne",
                "variableName": "streetLineOne"
              },
              {
                "kind": "Variable",
                "name": "streetLineTwo",
                "variableName": "streetLineTwo"
              },
              {
                "kind": "Variable",
                "name": "zip",
                "variableName": "zip"
              }
            ],
            "kind": "ObjectValue",
            "name": "mailingAddress"
          },
          {
            "kind": "Variable",
            "name": "personId",
            "variableName": "personId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdatePersonPayload",
    "kind": "LinkedField",
    "name": "updatePerson",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "mailingAddress",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "streetLineOne",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "streetLineTwo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zip",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateShippingAddressMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateShippingAddressMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "898254757c08ef11ed20b73831915113",
    "id": null,
    "metadata": {},
    "name": "UpdateShippingAddressMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateShippingAddressMutation(\n  $personId: ID!\n  $streetLineOne: String!\n  $streetLineTwo: String\n  $city: String!\n  $state: String!\n  $zip: String!\n) {\n  updatePerson(input: {personId: $personId, mailingAddress: {streetLineOne: $streetLineOne, streetLineTwo: $streetLineTwo, city: $city, state: $state, zip: $zip}}) {\n    person {\n      id\n      mailingAddress {\n        streetLineOne\n        streetLineTwo\n        city\n        state\n        zip\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8bf9e1830d71b7ab1ec891e96a476ed6";

export default node;
