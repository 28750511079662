/**
 * @generated SignedSource<<a9bb6cc3603f26b4c3350dd915cba34b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type otcStoreShoppingFragment$data = {
  readonly id: string;
  readonly resolvedOtcCatalog: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"otcStoreShoppingCollectionsFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"expirationBannerFragment" | "itemPersonFragment" | "ordersStatusModuleFragment">;
  readonly " $fragmentType": "otcStoreShoppingFragment";
};
export type otcStoreShoppingFragment$key = {
  readonly " $data"?: otcStoreShoppingFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"otcStoreShoppingFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "otcStoreShoppingFragment",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ordersStatusModuleFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "itemPersonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "expirationBannerFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterCatalog",
      "kind": "LinkedField",
      "name": "resolvedOtcCatalog",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "otcStoreShoppingCollectionsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "6bbd8aa0fabbd79aea67bf2cbbd387a7";

export default node;
