/**
 * @generated SignedSource<<8e91b9d7a14369b7d004f5dd63947de0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Sex = "FEMALE" | "MALE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type personalInfoFragment$data = {
  readonly birthDate: string | null;
  readonly id: string;
  readonly legalFirstName: string | null;
  readonly legalLastName: string | null;
  readonly sex: Sex | null;
  readonly " $fragmentSpreads": FragmentRefs<"personalInfoFlowFragment" | "personalInformationFlowModalFragment">;
  readonly " $fragmentType": "personalInfoFragment";
};
export type personalInfoFragment$key = {
  readonly " $data"?: personalInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"personalInfoFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "personalInfoFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "personalInfoFlowFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "personalInformationFlowModalFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalLastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sex",
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "7d2d52f6ab70cb58df0453ff4230aaaa";

export default node;
