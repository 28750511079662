/**
 * @generated SignedSource<<8a20e8667c0f8cdbc6b4ddc7b39ddc8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FullStoryIdentifierQuery$variables = {};
export type FullStoryIdentifierQuery$data = {
  readonly account: {
    readonly person: {
      readonly id: string;
      readonly legalLastName: string | null;
      readonly preferredFirstName: string | null;
    };
  };
} | null;
export type FullStoryIdentifierQuery = {
  response: FullStoryIdentifierQuery$data;
  variables: FullStoryIdentifierQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferredFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalLastName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FullStoryIdentifierQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "ChapterAccount",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": (v1/*: any*/),
              "action": "NONE",
              "path": "account.person"
            }
          ],
          "storageKey": null
        },
        "action": "NONE",
        "path": "account"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FullStoryIdentifierQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "689f7fe1a59ec360eb6ca435040e4558",
    "id": null,
    "metadata": {},
    "name": "FullStoryIdentifierQuery",
    "operationKind": "query",
    "text": "query FullStoryIdentifierQuery {\n  account {\n    person {\n      id\n      preferredFirstName\n      legalLastName\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9412dfb6e49c18d498fc4641702fb871";

export default node;
