/**
 * @generated SignedSource<<0d1da09a418d30e95c0075c78d52d49b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type cartAlertProviderQuery$variables = {};
export type cartAlertProviderQuery$data = {
  readonly account: {
    readonly person: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"cartAlertProviderRemovalsFragment">;
    } | null;
  } | null;
};
export type cartAlertProviderQuery = {
  response: cartAlertProviderQuery$data;
  variables: cartAlertProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "cartAlertProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "cartAlertProviderRemovalsFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "cartAlertProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OverTheCounterCatalog",
                "kind": "LinkedField",
                "name": "resolvedOtcCatalog",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OverTheCounterCartItem",
                "kind": "LinkedField",
                "name": "overTheCounterCartItems",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "quantity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OverTheCounterItem",
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OverTheCounterCatalog",
                        "kind": "LinkedField",
                        "name": "catalog",
                        "plural": false,
                        "selections": (v1/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d1efa0cbaf0a032909ee526835693c9",
    "id": null,
    "metadata": {},
    "name": "cartAlertProviderQuery",
    "operationKind": "query",
    "text": "query cartAlertProviderQuery {\n  account {\n    person {\n      id\n      ...cartAlertProviderRemovalsFragment\n    }\n    id\n  }\n}\n\nfragment cartAlertProviderRemovalsFragment on Person {\n  id\n  resolvedOtcCatalog {\n    id\n  }\n  overTheCounterCartItems {\n    quantity\n    item {\n      id\n      name\n      imageUrl\n      price\n      catalog {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2774cca47bc306b5c12e5d7b2448052d";

export default node;
