/**
 * @generated SignedSource<<aebb798f4b5b17886816ca3c9d5a33d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NightingaleGrowthbookProviderPersonQuery$variables = {};
export type NightingaleGrowthbookProviderPersonQuery$data = {
  readonly account: {
    readonly person: {
      readonly id: string;
    };
  } | null;
};
export type NightingaleGrowthbookProviderPersonQuery = {
  response: NightingaleGrowthbookProviderPersonQuery$data;
  variables: NightingaleGrowthbookProviderPersonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NightingaleGrowthbookProviderPersonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v1/*: any*/),
            "action": "NONE",
            "path": "account.person"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NightingaleGrowthbookProviderPersonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "279839d6d0a996db575683b4a83fdd98",
    "id": null,
    "metadata": {},
    "name": "NightingaleGrowthbookProviderPersonQuery",
    "operationKind": "query",
    "text": "query NightingaleGrowthbookProviderPersonQuery {\n  account {\n    person {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf47521ca81016e1e1919a99e63de1c6";

export default node;
