/**
 * @generated SignedSource<<24fd7f43b1d71eb6996bdb724f0f826e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExperimentAssigneeType = "DEVICE" | "PERSON" | "%future added value";
export type ExperimentAssignmentInput = {
  assignee?: ExperimentAssigneeInput | null;
  deviceId?: string | null;
  experimentId: string;
  experimentUrl?: string | null;
  leadId?: string | null;
  timestamp: number;
  variantId: number;
};
export type ExperimentAssigneeInput = {
  assigneeId: string;
  type: ExperimentAssigneeType;
};
export type NightingaleGrowthbookProviderRecordExperimentAssignmentMutation$variables = {
  input: ExperimentAssignmentInput;
};
export type NightingaleGrowthbookProviderRecordExperimentAssignmentMutation$data = {
  readonly recordExperimentAssignment: boolean;
};
export type NightingaleGrowthbookProviderRecordExperimentAssignmentMutation = {
  response: NightingaleGrowthbookProviderRecordExperimentAssignmentMutation$data;
  variables: NightingaleGrowthbookProviderRecordExperimentAssignmentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "recordExperimentAssignment",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NightingaleGrowthbookProviderRecordExperimentAssignmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NightingaleGrowthbookProviderRecordExperimentAssignmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2170dce63b82a6afef86225993719a07",
    "id": null,
    "metadata": {},
    "name": "NightingaleGrowthbookProviderRecordExperimentAssignmentMutation",
    "operationKind": "mutation",
    "text": "mutation NightingaleGrowthbookProviderRecordExperimentAssignmentMutation(\n  $input: ExperimentAssignmentInput!\n) {\n  recordExperimentAssignment(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "926ab7ccb71abe553b60ed30938543ac";

export default node;
