/**
 * @generated SignedSource<<86da624b3965328b4ba2d630eee52220>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type pdpCoverageQuery$variables = {};
export type pdpCoverageQuery$data = {
  readonly account: {
    readonly person: {
      readonly supplementalCoverageHistory: {
        readonly currentCoverage: {
          readonly drugCoverage: {
            readonly membershipStartDate?: string | null;
            readonly plan?: {
              readonly evidenceOfCoverageUrl: string | null;
              readonly organizationMarketingName: string;
              readonly organizationWebsiteUrl: string | null;
              readonly planName: string;
              readonly summaryOfBenefitsUrl: string | null;
            };
            readonly planId?: string | null;
          };
        };
      };
      readonly " $fragmentSpreads": FragmentRefs<"coveragePageLayoutFragment">;
    } | null;
  } | null;
};
export type pdpCoverageQuery = {
  response: pdpCoverageQuery$data;
  variables: pdpCoverageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "membershipStartDate",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationMarketingName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "evidenceOfCoverageUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summaryOfBenefitsUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationWebsiteUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "medicarePlanType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  (v8/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AMedicareAdvantagePartDPlan",
      "kind": "LinkedField",
      "name": "plan",
      "plural": false,
      "selections": (v10/*: any*/),
      "storageKey": null
    },
    (v9/*: any*/)
  ],
  "type": "MedicareAdvantagePartDPolicy",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "pdpCoverageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "coveragePageLayoutFragment"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "alias": null,
                  "args": null,
                  "concreteType": "SupplementalCoverageHistory",
                  "kind": "LinkedField",
                  "name": "supplementalCoverageHistory",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "concreteType": "SupplementalCoverage",
                        "kind": "LinkedField",
                        "name": "currentCoverage",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "drugCoverage",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v0/*: any*/),
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "APartDPlan",
                                      "kind": "LinkedField",
                                      "name": "plan",
                                      "plural": false,
                                      "selections": [
                                        (v2/*: any*/),
                                        (v3/*: any*/),
                                        (v4/*: any*/),
                                        (v5/*: any*/),
                                        (v6/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "MedicarePartDPolicy",
                                  "abstractKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            "action": "NONE",
                            "path": "account.person.supplementalCoverageHistory.currentCoverage.drugCoverage"
                          }
                        ],
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "account.person.supplementalCoverageHistory.currentCoverage"
                    }
                  ],
                  "storageKey": null
                },
                "action": "NONE",
                "path": "account.person.supplementalCoverageHistory"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "pdpCoverageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SupplementalCoverageHistory",
                "kind": "LinkedField",
                "name": "supplementalCoverageHistory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SupplementalCoverage",
                    "kind": "LinkedField",
                    "name": "currentCoverage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "drugCoverage",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v11/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "APartDPlan",
                                "kind": "LinkedField",
                                "name": "plan",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v9/*: any*/),
                              (v0/*: any*/),
                              (v1/*: any*/)
                            ],
                            "type": "MedicarePartDPolicy",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "medicalCoverage",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v11/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AMedicareAdvantagePlan",
                                "kind": "LinkedField",
                                "name": "plan",
                                "plural": false,
                                "selections": (v10/*: any*/),
                                "storageKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "type": "MedicareAdvantagePolicy",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AMedicareSupplementPlan",
                                "kind": "LinkedField",
                                "name": "medicareSupplementPlan",
                                "plural": false,
                                "selections": (v10/*: any*/),
                                "storageKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "type": "MedicareSupplementPolicy",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MedicareProfile",
                "kind": "LinkedField",
                "name": "medicareProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "medicareBeneficiaryId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1a086a2c5261a364ec0b1a1dff22445",
    "id": null,
    "metadata": {},
    "name": "pdpCoverageQuery",
    "operationKind": "query",
    "text": "query pdpCoverageQuery {\n  account {\n    person {\n      ...coveragePageLayoutFragment\n      supplementalCoverageHistory {\n        currentCoverage {\n          drugCoverage {\n            __typename\n            ... on MedicarePartDPolicy {\n              membershipStartDate\n              planId\n              plan {\n                planName\n                organizationMarketingName\n                evidenceOfCoverageUrl\n                summaryOfBenefitsUrl\n                organizationWebsiteUrl\n                id\n              }\n              id\n            }\n            ... on MedicareAdvantagePartDPolicy {\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment coveragePageLayoutFragment on Person {\n  supplementalCoverageHistory {\n    currentCoverage {\n      drugCoverage {\n        __typename\n        ... on MedicareAdvantagePartDPolicy {\n          plan {\n            medicarePlanType\n            id\n          }\n          id\n        }\n        ... on MedicarePartDPolicy {\n          plan {\n            medicarePlanType\n            id\n          }\n          id\n        }\n      }\n      medicalCoverage {\n        __typename\n        ... on MedicareAdvantagePartDPolicy {\n          plan {\n            medicarePlanType\n            id\n          }\n          id\n        }\n        ... on MedicareAdvantagePolicy {\n          plan {\n            medicarePlanType\n            id\n          }\n          id\n        }\n        ... on MedicareSupplementPolicy {\n          medicareSupplementPlan {\n            medicarePlanType\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n  medicareProfile {\n    medicareBeneficiaryId\n  }\n}\n"
  }
};
})();

(node as any).hash = "1bce720242e438620daafa702d5939b2";

export default node;
