/**
 * @generated SignedSource<<31434b66fa611fdf70463cc142411f9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Sex = "FEMALE" | "MALE" | "%future added value";
export type UpdatePersonMutation$variables = {
  birthDate?: string | null;
  legalFirstName?: string | null;
  legalLastName?: string | null;
  personId: string;
  sex?: Sex | null;
};
export type UpdatePersonMutation$data = {
  readonly updatePerson: {
    readonly person: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"personalInfoFragment">;
    };
  } | null;
};
export type UpdatePersonMutation = {
  response: UpdatePersonMutation$data;
  variables: UpdatePersonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "birthDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "legalFirstName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "legalLastName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "personId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sex"
},
v5 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "birthDate",
        "variableName": "birthDate"
      },
      {
        "kind": "Variable",
        "name": "legalFirstName",
        "variableName": "legalFirstName"
      },
      {
        "kind": "Variable",
        "name": "legalLastName",
        "variableName": "legalLastName"
      },
      {
        "kind": "Variable",
        "name": "personId",
        "variableName": "personId"
      },
      {
        "kind": "Variable",
        "name": "sex",
        "variableName": "sex"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePersonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "UpdatePersonPayload",
        "kind": "LinkedField",
        "name": "updatePerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "personalInfoFragment"
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdatePersonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "UpdatePersonPayload",
        "kind": "LinkedField",
        "name": "updatePerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalFirstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalLastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "birthDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sex",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e500eec80cbafa68e60aef15d866655",
    "id": null,
    "metadata": {},
    "name": "UpdatePersonMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePersonMutation(\n  $personId: ID!\n  $legalFirstName: String\n  $legalLastName: String\n  $birthDate: Date\n  $sex: Sex\n) {\n  updatePerson(input: {personId: $personId, legalFirstName: $legalFirstName, legalLastName: $legalLastName, birthDate: $birthDate, sex: $sex}) {\n    person {\n      ...personalInfoFragment\n      id\n    }\n  }\n}\n\nfragment personalInfoFlowFragment on Person {\n  id\n  legalFirstName\n  legalLastName\n  birthDate\n  sex\n}\n\nfragment personalInfoFragment on Person {\n  ...personalInfoFlowFragment\n  ...personalInformationFlowModalFragment\n  id\n  legalFirstName\n  legalLastName\n  birthDate\n  sex\n}\n\nfragment personalInformationFlowModalFragment on Person {\n  id\n  ...personalInfoFlowFragment\n}\n"
  }
};
})();

(node as any).hash = "ba13dcdcb664387dc0063ca4f662cad6";

export default node;
