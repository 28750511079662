/**
 * @generated SignedSource<<7bca2c95f64c425e7223e8fedd7d08e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type descriptionHeaderFragment$data = {
  readonly account: {
    readonly person: {
      readonly " $fragmentSpreads": FragmentRefs<"cartButtonFragment">;
    } | null;
  } | null;
  readonly " $fragmentType": "descriptionHeaderFragment";
};
export type descriptionHeaderFragment$key = {
  readonly " $data"?: descriptionHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"descriptionHeaderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "descriptionHeaderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChapterAccount",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "cartButtonFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0bff92d08ebcb9d170511deaf6110fd4";

export default node;
