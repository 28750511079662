/**
 * @generated SignedSource<<f915400d5e1329ce4dbd67b5dae3c6ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type originalMedicareCoverageQuery$variables = {};
export type originalMedicareCoverageQuery$data = {
  readonly account: {
    readonly person: {
      readonly medicareProfile: {
        readonly medicareBeneficiaryId: string;
        readonly partAEffectiveDate: string | null;
        readonly partBEffectiveDate: string | null;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"coveragePageLayoutFragment" | "medicareInfoModalFragment" | "sharedMedicareCardFragment">;
    } | null;
  } | null;
};
export type originalMedicareCoverageQuery = {
  response: originalMedicareCoverageQuery$data;
  variables: originalMedicareCoverageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "medicareBeneficiaryId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "partAEffectiveDate",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "partBEffectiveDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "medicarePlanType",
    "storageKey": null
  },
  (v4/*: any*/)
],
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AMedicareAdvantagePartDPlan",
      "kind": "LinkedField",
      "name": "plan",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "type": "MedicareAdvantagePartDPolicy",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "originalMedicareCoverageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "coveragePageLayoutFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "medicareInfoModalFragment"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MedicareProfile",
                "kind": "LinkedField",
                "name": "medicareProfile",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v0/*: any*/),
                    "action": "NONE",
                    "path": "account.person.medicareProfile.medicareBeneficiaryId"
                  },
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "sharedMedicareCardFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "originalMedicareCoverageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SupplementalCoverageHistory",
                "kind": "LinkedField",
                "name": "supplementalCoverageHistory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SupplementalCoverage",
                    "kind": "LinkedField",
                    "name": "currentCoverage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "drugCoverage",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "APartDPlan",
                                "kind": "LinkedField",
                                "name": "plan",
                                "plural": false,
                                "selections": (v5/*: any*/),
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "type": "MedicarePartDPolicy",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "medicalCoverage",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AMedicareAdvantagePlan",
                                "kind": "LinkedField",
                                "name": "plan",
                                "plural": false,
                                "selections": (v5/*: any*/),
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "type": "MedicareAdvantagePolicy",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AMedicareSupplementPlan",
                                "kind": "LinkedField",
                                "name": "medicareSupplementPlan",
                                "plural": false,
                                "selections": (v5/*: any*/),
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "type": "MedicareSupplementPolicy",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MedicareProfile",
                "kind": "LinkedField",
                "name": "medicareProfile",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalFirstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalLastName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1a025b7d55565065b68b7d1315c970e8",
    "id": null,
    "metadata": {},
    "name": "originalMedicareCoverageQuery",
    "operationKind": "query",
    "text": "query originalMedicareCoverageQuery {\n  account {\n    person {\n      ...coveragePageLayoutFragment\n      ...medicareInfoModalFragment\n      medicareProfile {\n        medicareBeneficiaryId\n        partAEffectiveDate\n        partBEffectiveDate\n      }\n      ...sharedMedicareCardFragment\n      id\n    }\n    id\n  }\n}\n\nfragment coveragePageLayoutFragment on Person {\n  supplementalCoverageHistory {\n    currentCoverage {\n      drugCoverage {\n        __typename\n        ... on MedicareAdvantagePartDPolicy {\n          plan {\n            medicarePlanType\n            id\n          }\n          id\n        }\n        ... on MedicarePartDPolicy {\n          plan {\n            medicarePlanType\n            id\n          }\n          id\n        }\n      }\n      medicalCoverage {\n        __typename\n        ... on MedicareAdvantagePartDPolicy {\n          plan {\n            medicarePlanType\n            id\n          }\n          id\n        }\n        ... on MedicareAdvantagePolicy {\n          plan {\n            medicarePlanType\n            id\n          }\n          id\n        }\n        ... on MedicareSupplementPolicy {\n          medicareSupplementPlan {\n            medicarePlanType\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n  medicareProfile {\n    medicareBeneficiaryId\n  }\n}\n\nfragment medicareInfoFormFragment on Person {\n  id\n  legalFirstName\n  legalLastName\n  medicareProfile {\n    medicareBeneficiaryId\n    partAEffectiveDate\n    partBEffectiveDate\n  }\n}\n\nfragment medicareInfoModalFragment on Person {\n  ...medicareInfoFormFragment\n}\n\nfragment sharedMedicareCardFragment on Person {\n  legalFirstName\n  legalLastName\n  medicareProfile {\n    medicareBeneficiaryId\n    partAEffectiveDate\n    partBEffectiveDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "56e1c2dfad5a17c475843ce466ec3692";

export default node;
