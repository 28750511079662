/**
 * @generated SignedSource<<e9cc14bdb478850a01f4f551ae019d16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type screen_orderHistoryQuery$variables = {};
export type screen_orderHistoryQuery$data = {
  readonly account: {
    readonly person: {
      readonly id: string;
      readonly otcOrderHistory: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly cancellation: {
              readonly canceledAt: string;
            } | null;
            readonly deliveredAt: string | null;
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"orderSummaryOrderFragment" | "orderTrackingWidgetFragment">;
          };
        }>;
      };
    } | null;
  };
};
export type screen_orderHistoryQuery = {
  response: screen_orderHistoryQuery$data;
  variables: screen_orderHistoryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deliveredAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canceledAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "screen_orderHistoryQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "ChapterAccount",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": "otcOrderHistory",
                  "args": null,
                  "concreteType": "OverTheCounterOrderConnection",
                  "kind": "LinkedField",
                  "name": "__screenOtcHistory_otcOrderHistory_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OverTheCounterOrderEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "OverTheCounterOrder",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "OverTheCounterOrderCancellation",
                              "kind": "LinkedField",
                              "name": "cancellation",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "orderTrackingWidgetFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "orderSummaryOrderFragment"
                            },
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v4/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "account"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "screen_orderHistoryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "OverTheCounterOrderConnection",
                "kind": "LinkedField",
                "name": "otcOrderHistory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OverTheCounterOrderEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OverTheCounterOrder",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OverTheCounterOrderCancellation",
                            "kind": "LinkedField",
                            "name": "cancellation",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reason",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "placedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "submittedToProviderAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OverTheCounterOrderProblem",
                            "kind": "LinkedField",
                            "name": "problems",
                            "plural": true,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OverTheCounterOrderProblemResolution",
                                "kind": "LinkedField",
                                "name": "resolution",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "resolvedAt",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 50
                              }
                            ],
                            "concreteType": "OverTheCounterOrderItemConnection",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OverTheCounterOrderItemEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OverTheCounterOrderItem",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "quantity",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "OverTheCounterItem",
                                        "kind": "LinkedField",
                                        "name": "item",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "price",
                                            "storageKey": null
                                          },
                                          (v0/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "items(first:50)"
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": "otcOrderHistory(first:100)"
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "screenOtcHistory_otcOrderHistory",
                "kind": "LinkedHandle",
                "name": "otcOrderHistory"
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4048822c593f63d23d90367309f78dfb",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "account",
            "person",
            "otcOrderHistory"
          ]
        }
      ]
    },
    "name": "screen_orderHistoryQuery",
    "operationKind": "query",
    "text": "query screen_orderHistoryQuery {\n  account {\n    person {\n      id\n      otcOrderHistory(first: 100) {\n        edges {\n          node {\n            id\n            deliveredAt\n            cancellation {\n              canceledAt\n            }\n            ...orderTrackingWidgetFragment\n            ...orderSummaryOrderFragment\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment orderProgressOrderFragment on OverTheCounterOrder {\n  id\n  placedAt\n  deliveredAt\n  submittedToProviderAt\n  problems {\n    id\n    resolution {\n      resolvedAt\n    }\n  }\n  cancellation {\n    reason\n    canceledAt\n  }\n}\n\nfragment orderSummaryOrderFragment on OverTheCounterOrder {\n  items(first: 50) {\n    edges {\n      node {\n        quantity\n        item {\n          price\n          id\n        }\n      }\n    }\n  }\n  placedAt\n  deliveredAt\n  cancellation {\n    canceledAt\n  }\n}\n\nfragment orderTrackingWidgetFragment on OverTheCounterOrder {\n  id\n  ...orderProgressOrderFragment\n  ...orderSummaryOrderFragment\n}\n"
  }
};
})();

(node as any).hash = "ced1ef8ae9c3e7a5d49aa35d42ee471c";

export default node;
