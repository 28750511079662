/**
 * @generated SignedSource<<5be86cea47ef453202a64898a6303f20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type enterUserPolicyFlowFragment$data = {
  readonly byopState: {
    readonly carrierOrganizationMarketingName: string | null;
  };
  readonly id: string;
  readonly legalFirstName: string | null;
  readonly legalLastName: string | null;
  readonly " $fragmentType": "enterUserPolicyFlowFragment";
};
export type enterUserPolicyFlowFragment$key = {
  readonly " $data"?: enterUserPolicyFlowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"enterUserPolicyFlowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "enterUserPolicyFlowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalLastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ByopState",
      "kind": "LinkedField",
      "name": "byopState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "carrierOrganizationMarketingName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "dc8bab26aaa33524da8e138bc1583f23";

export default node;
