/**
 * @generated SignedSource<<5f8c3fd4227b6bd313e590e7e9d81fb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type referralDrawerFragment$data = {
  readonly consumerAppReferralCode: string;
  readonly id: string;
  readonly legalFirstName: string | null;
  readonly legalLastName: string | null;
  readonly referrals: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
    }>;
    readonly eligibleMonetaryValueSent: number;
    readonly totalCount: number;
  };
  readonly " $fragmentType": "referralDrawerFragment";
};
export type referralDrawerFragment$key = {
  readonly " $data"?: referralDrawerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"referralDrawerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "referralDrawerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalLastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "consumerAppReferralCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        }
      ],
      "concreteType": "ReferralConnection",
      "kind": "LinkedField",
      "name": "referrals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReferralEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eligibleMonetaryValueSent",
          "storageKey": null
        }
      ],
      "storageKey": "referrals(first:10)"
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "933b82d27639966db5e2e5db269d76cf";

export default node;
