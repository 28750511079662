/**
 * @generated SignedSource<<88e4412588b63f5dca9982c843d9885b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type shippingInfoFragment$data = {
  readonly id: string;
  readonly mailingAddress: {
    readonly city: string | null;
    readonly state: string | null;
    readonly streetLineOne: string | null;
    readonly streetLineTwo: string | null;
    readonly zip: string | null;
  } | null;
  readonly " $fragmentType": "shippingInfoFragment";
};
export type shippingInfoFragment$key = {
  readonly " $data"?: shippingInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"shippingInfoFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "shippingInfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "mailingAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetLineOne",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "streetLineTwo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zip",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "65b98221210b6cb69a7c4a727f1da362";

export default node;
