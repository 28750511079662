/**
 * @generated SignedSource<<1fe32155614640a78275841a7dc2be1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type cartAlertProviderRemovalsFragment$data = {
  readonly id: string;
  readonly overTheCounterCartItems: ReadonlyArray<{
    readonly item: {
      readonly catalog: {
        readonly id: string;
      };
      readonly id: string;
      readonly imageUrl: string | null;
      readonly name: string;
      readonly price: number;
    };
    readonly quantity: number;
  }>;
  readonly resolvedOtcCatalog: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "cartAlertProviderRemovalsFragment";
};
export type cartAlertProviderRemovalsFragment$key = {
  readonly " $data"?: cartAlertProviderRemovalsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"cartAlertProviderRemovalsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "cartAlertProviderRemovalsFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterCatalog",
      "kind": "LinkedField",
      "name": "resolvedOtcCatalog",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterCartItem",
      "kind": "LinkedField",
      "name": "overTheCounterCartItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OverTheCounterItem",
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OverTheCounterCatalog",
              "kind": "LinkedField",
              "name": "catalog",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "28746b42db35b56a6e787a801ffa7100";

export default node;
