/**
 * @generated SignedSource<<c115cead1317d70c6fa632b571bcf26c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type otcStoreFragment$data = {
  readonly account: {
    readonly person: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"HeaderFragment" | "enterUserPolicyFlowFragment" | "ordersStatusModuleFragment" | "otcStoreShoppingFragment" | "removeUserEnteredPolicyModalFragment" | "useOtcShoppingStateFragment">;
    };
  };
  readonly " $fragmentType": "otcStoreFragment";
};
export type otcStoreFragment$key = {
  readonly " $data"?: otcStoreFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"otcStoreFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "otcStoreFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "useOtcShoppingStateFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ordersStatusModuleFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "otcStoreShoppingFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "enterUserPolicyFlowFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "HeaderFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "removeUserEnteredPolicyModalFragment"
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "account.person"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "account"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "bcc12ea0f7b64dfe317a2fd114e32496";

export default node;
