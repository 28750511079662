/**
 * @generated SignedSource<<b8bb0b0ec5068f1dda3905e9c237c597>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type usePlanBenefitLookup_countiesQuery$variables = {
  zipCode: string;
};
export type usePlanBenefitLookup_countiesQuery$data = {
  readonly counties: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly countyName: string;
        readonly fipsCountyCode: any;
        readonly id: string;
        readonly state: string;
      };
    }>;
  };
};
export type usePlanBenefitLookup_countiesQuery = {
  response: usePlanBenefitLookup_countiesQuery$data;
  variables: usePlanBenefitLookup_countiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "zipCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "zipCode",
            "variableName": "zipCode"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "CountyConnection",
    "kind": "LinkedField",
    "name": "counties",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CountyEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ACounty",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countyName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fipsCountyCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePlanBenefitLookup_countiesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePlanBenefitLookup_countiesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4e49931e680362fd82398acb2872e1c1",
    "id": null,
    "metadata": {},
    "name": "usePlanBenefitLookup_countiesQuery",
    "operationKind": "query",
    "text": "query usePlanBenefitLookup_countiesQuery(\n  $zipCode: String!\n) {\n  counties(filter: {zipCode: $zipCode}) {\n    edges {\n      node {\n        id\n        countyName\n        fipsCountyCode\n        state\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "21cd06ff2cbfd4dce80252dfa1b74896";

export default node;
