/**
 * @generated SignedSource<<7d3cae5f250721c0cb9e4d003657a130>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type screenAccountSettingsQuery$variables = {};
export type screenAccountSettingsQuery$data = {
  readonly account: {
    readonly person: {
      readonly id: string;
    };
  };
};
export type screenAccountSettingsQuery = {
  response: screenAccountSettingsQuery$data;
  variables: screenAccountSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "screenAccountSettingsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "ChapterAccount",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": (v1/*: any*/),
              "action": "THROW",
              "path": "account.person"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "account"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "screenAccountSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "298cd750e9ddac0b09e80a4f7fc50e2b",
    "id": null,
    "metadata": {},
    "name": "screenAccountSettingsQuery",
    "operationKind": "query",
    "text": "query screenAccountSettingsQuery {\n  account {\n    person {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7d064b9e3e8d7895cdc8a94221c62ff7";

export default node;
