/**
 * @generated SignedSource<<a517338cbdefd86e94a611be3c7a3209>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type addressAutocompleteQuery$variables = {
  query: string;
};
export type addressAutocompleteQuery$data = {
  readonly results: {
    readonly addresses: ReadonlyArray<{
      readonly city: string | null;
      readonly state: string | null;
      readonly streetLineOne: string | null;
      readonly streetLineTwo: string | null;
      readonly zip: string | null;
    }>;
  };
};
export type addressAutocompleteQuery = {
  response: addressAutocompleteQuery$data;
  variables: addressAutocompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "alias": "results",
    "args": [
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "bias",
            "value": {
              "ipAddress": true
            }
          },
          {
            "kind": "Variable",
            "name": "query",
            "variableName": "query"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "AddressSearchResult",
    "kind": "LinkedField",
    "name": "addressSearch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Address",
        "kind": "LinkedField",
        "name": "addresses",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "streetLineOne",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "streetLineTwo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zip",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addressAutocompleteQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addressAutocompleteQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9f019a137c1a8abe500ba2d8b96dbc46",
    "id": null,
    "metadata": {},
    "name": "addressAutocompleteQuery",
    "operationKind": "query",
    "text": "query addressAutocompleteQuery(\n  $query: String!\n) {\n  results: addressSearch(input: {query: $query, bias: {ipAddress: true}}) {\n    addresses {\n      streetLineOne\n      streetLineTwo\n      city\n      state\n      zip\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "991aca6ea4f028d36367dfaa88dcd760";

export default node;
