/**
 * @generated SignedSource<<d6bba9bbdb854cd879b9ff0a154dffc5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Frequency = "ANNUALLY" | "EVERY_THREE_YEARS" | "EVERY_TWO_YEARS" | "MONTHLY" | "QUARTERLY" | "TWICE_A_YEAR" | "UNKNOWN" | "%future added value";
export type PartCPlanFilter = {
  bidIds?: ReadonlyArray<any> | null;
  contractId?: string | null;
  contractYear?: number | null;
  fipsCountyCode?: any | null;
  includeEmployerOnlyPlans?: boolean | null;
  planId?: string | null;
  segmentId?: string | null;
};
export type queries_partCPlanQuery$variables = {
  filter: PartCPlanFilter;
};
export type queries_partCPlanQuery$data = {
  readonly medicareAdvantagePartDPlans: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "AMedicareAdvantagePartDPlan";
        readonly bidId: any;
        readonly medicarePlanId: string;
        readonly organizationMarketingName: string;
        readonly overTheCounterBenefit: {
          readonly benefitFrequency: Frequency;
          readonly dollarAmount: number;
        } | null;
        readonly planName: string;
      };
    }>;
  };
  readonly medicareAdvantagePlans: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "AMedicareAdvantagePlan";
        readonly bidId: any;
        readonly medicarePlanId: string;
        readonly organizationMarketingName: string;
        readonly overTheCounterBenefit: {
          readonly benefitFrequency: Frequency;
          readonly dollarAmount: number;
        } | null;
        readonly planName: string;
      };
    }>;
  };
};
export type queries_partCPlanQuery = {
  response: queries_partCPlanQuery$data;
  variables: queries_partCPlanQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "medicarePlanId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationMarketingName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "OverTheCounterBenefit",
  "kind": "LinkedField",
  "name": "overTheCounterBenefit",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dollarAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "benefitFrequency",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queries_partCPlanQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MedicareAdvantagePartDPlanConnection",
        "kind": "LinkedField",
        "name": "medicareAdvantagePartDPlans",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MedicareAdvantagePartDPlanEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AMedicareAdvantagePartDPlan",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MedicareAdvantagePlanConnection",
        "kind": "LinkedField",
        "name": "medicareAdvantagePlans",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MedicareAdvantagePlanEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AMedicareAdvantagePlan",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queries_partCPlanQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MedicareAdvantagePartDPlanConnection",
        "kind": "LinkedField",
        "name": "medicareAdvantagePartDPlans",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MedicareAdvantagePartDPlanEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AMedicareAdvantagePartDPlan",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MedicareAdvantagePlanConnection",
        "kind": "LinkedField",
        "name": "medicareAdvantagePlans",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MedicareAdvantagePlanEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AMedicareAdvantagePlan",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00f341030e6a4ca4b37ece3ed09eb8da",
    "id": null,
    "metadata": {},
    "name": "queries_partCPlanQuery",
    "operationKind": "query",
    "text": "query queries_partCPlanQuery(\n  $filter: PartCPlanFilter!\n) {\n  medicareAdvantagePartDPlans(filter: $filter) {\n    edges {\n      node {\n        medicarePlanId\n        organizationMarketingName\n        planName\n        bidId\n        overTheCounterBenefit {\n          dollarAmount\n          benefitFrequency\n        }\n        __typename\n        id\n      }\n    }\n  }\n  medicareAdvantagePlans(filter: $filter) {\n    edges {\n      node {\n        medicarePlanId\n        organizationMarketingName\n        planName\n        bidId\n        overTheCounterBenefit {\n          dollarAmount\n          benefitFrequency\n        }\n        __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e465fb8d77ac3edfe72247feba41166";

export default node;
