/**
 * @generated SignedSource<<c46c877d2a2eb43c80ca0e01da92d792>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type removeUserEnteredPolicyModalFragment$data = {
  readonly supplementalCoverageHistory: {
    readonly currentCoverage: {
      readonly medicalCoverage: {
        readonly id?: string;
        readonly plan?: {
          readonly organizationMarketingName: string;
        };
      };
    };
  };
  readonly " $fragmentType": "removeUserEnteredPolicyModalFragment";
} | null;
export type removeUserEnteredPolicyModalFragment$key = {
  readonly " $data"?: removeUserEnteredPolicyModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"removeUserEnteredPolicyModalFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "organizationMarketingName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "removeUserEnteredPolicyModalFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "SupplementalCoverageHistory",
        "kind": "LinkedField",
        "name": "supplementalCoverageHistory",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "SupplementalCoverage",
              "kind": "LinkedField",
              "name": "currentCoverage",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "medicalCoverage",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v0/*: any*/),
                          {
                            "kind": "RequiredField",
                            "field": {
                              "alias": null,
                              "args": null,
                              "concreteType": "AMedicareAdvantagePartDPlan",
                              "kind": "LinkedField",
                              "name": "plan",
                              "plural": false,
                              "selections": (v1/*: any*/),
                              "storageKey": null
                            },
                            "action": "NONE",
                            "path": "supplementalCoverageHistory.currentCoverage.medicalCoverage.plan"
                          }
                        ],
                        "type": "MedicareAdvantagePartDPolicy",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v0/*: any*/),
                          {
                            "kind": "RequiredField",
                            "field": {
                              "alias": null,
                              "args": null,
                              "concreteType": "AMedicareAdvantagePlan",
                              "kind": "LinkedField",
                              "name": "plan",
                              "plural": false,
                              "selections": (v1/*: any*/),
                              "storageKey": null
                            },
                            "action": "NONE",
                            "path": "supplementalCoverageHistory.currentCoverage.medicalCoverage.plan"
                          }
                        ],
                        "type": "MedicareAdvantagePolicy",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  "action": "NONE",
                  "path": "supplementalCoverageHistory.currentCoverage.medicalCoverage"
                }
              ],
              "storageKey": null
            },
            "action": "NONE",
            "path": "supplementalCoverageHistory.currentCoverage"
          }
        ],
        "storageKey": null
      },
      "action": "NONE",
      "path": "supplementalCoverageHistory"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "34fa76ba6cd75ae339af4676dcc8e5ed";

export default node;
