/**
 * @generated SignedSource<<18a427e940388e08f1279f479101420d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type expirationBannerFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useOtcShoppingStateFragment">;
  readonly " $fragmentType": "expirationBannerFragment";
};
export type expirationBannerFragment$key = {
  readonly " $data"?: expirationBannerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"expirationBannerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "expirationBannerFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOtcShoppingStateFragment"
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "9c0fa67de384aa9942e103537545ee8c";

export default node;
