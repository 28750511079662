/**
 * @generated SignedSource<<f6961920e623c0a9c5ca1ef9b0bb8bfd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeliveryAddressInput = {
  city: string;
  state: string;
  streetLineOne: string;
  streetLineTwo?: string | null;
  zip: string;
};
export type CheckoutCartMutation$variables = {
  deliveryAddress: DeliveryAddressInput;
  personId: string;
  policyId: string;
};
export type CheckoutCartMutation$data = {
  readonly checkoutOtcCart: {
    readonly person: {
      readonly otcOrderHistory: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"orderProgressOrderFragment" | "orderSummaryOrderFragment">;
          };
        }>;
      };
      readonly overTheCounterCartItems: ReadonlyArray<{
        readonly item: {
          readonly id: string;
        };
        readonly quantity: number;
      }>;
    };
  };
};
export type CheckoutCartMutation = {
  response: CheckoutCartMutation$data;
  variables: CheckoutCartMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deliveryAddress"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "personId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "policyId"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "deliveryAddress",
        "variableName": "deliveryAddress"
      },
      {
        "kind": "Variable",
        "name": "personId",
        "variableName": "personId"
      },
      {
        "kind": "Variable",
        "name": "policyId",
        "variableName": "policyId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "OverTheCounterCartItem",
  "kind": "LinkedField",
  "name": "overTheCounterCartItems",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterItem",
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutCartMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CheckoutOverTheCounterCartPayload",
        "kind": "LinkedField",
        "name": "checkoutOtcCart",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OverTheCounterOrderConnection",
                "kind": "LinkedField",
                "name": "otcOrderHistory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OverTheCounterOrderEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OverTheCounterOrder",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "orderProgressOrderFragment"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "orderSummaryOrderFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CheckoutCartMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CheckoutOverTheCounterCartPayload",
        "kind": "LinkedField",
        "name": "checkoutOtcCart",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OverTheCounterOrderConnection",
                "kind": "LinkedField",
                "name": "otcOrderHistory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OverTheCounterOrderEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OverTheCounterOrder",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "placedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deliveredAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "submittedToProviderAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OverTheCounterOrderProblem",
                            "kind": "LinkedField",
                            "name": "problems",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OverTheCounterOrderProblemResolution",
                                "kind": "LinkedField",
                                "name": "resolution",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "resolvedAt",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OverTheCounterOrderCancellation",
                            "kind": "LinkedField",
                            "name": "cancellation",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reason",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "canceledAt",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 50
                              }
                            ],
                            "concreteType": "OverTheCounterOrderItemConnection",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OverTheCounterOrderItemEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OverTheCounterOrderItem",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "OverTheCounterItem",
                                        "kind": "LinkedField",
                                        "name": "item",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "price",
                                            "storageKey": null
                                          },
                                          (v4/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "items(first:50)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a1841ca8833e78ae53da8a70198953d3",
    "id": null,
    "metadata": {},
    "name": "CheckoutCartMutation",
    "operationKind": "mutation",
    "text": "mutation CheckoutCartMutation(\n  $personId: ID!\n  $policyId: ID!\n  $deliveryAddress: DeliveryAddressInput!\n) {\n  checkoutOtcCart(input: {personId: $personId, policyId: $policyId, deliveryAddress: $deliveryAddress}) {\n    person {\n      overTheCounterCartItems {\n        item {\n          id\n        }\n        quantity\n      }\n      otcOrderHistory {\n        edges {\n          node {\n            id\n            ...orderProgressOrderFragment\n            ...orderSummaryOrderFragment\n          }\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment orderProgressOrderFragment on OverTheCounterOrder {\n  id\n  placedAt\n  deliveredAt\n  submittedToProviderAt\n  problems {\n    id\n    resolution {\n      resolvedAt\n    }\n  }\n  cancellation {\n    reason\n    canceledAt\n  }\n}\n\nfragment orderSummaryOrderFragment on OverTheCounterOrder {\n  items(first: 50) {\n    edges {\n      node {\n        quantity\n        item {\n          price\n          id\n        }\n      }\n    }\n  }\n  placedAt\n  deliveredAt\n  cancellation {\n    canceledAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8e23148d1c0bbfc6ac10148db7b8603";

export default node;
