/**
 * @generated SignedSource<<7093eef0044861165ba3239bebb53539>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Frequency = "ANNUALLY" | "EVERY_THREE_YEARS" | "EVERY_TWO_YEARS" | "MONTHLY" | "QUARTERLY" | "TWICE_A_YEAR" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type planInfoFragment$data = {
  readonly id: string;
  readonly supplementalCoverageHistory: {
    readonly currentCoverage: {
      readonly medicalCoverage: {
        readonly id?: string;
        readonly memberId?: string | null;
        readonly plan?: {
          readonly organizationMarketingName: string;
          readonly overTheCounterBenefit: {
            readonly benefitFrequency: Frequency;
            readonly dollarAmount: number;
          } | null;
        };
      } | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"enterUserPolicyFlowFragment" | "useCartInfoFragment" | "useOtcShoppingStateFragment">;
  readonly " $fragmentType": "planInfoFragment";
};
export type planInfoFragment$key = {
  readonly " $data"?: planInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"planInfoFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memberId",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "organizationMarketingName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "OverTheCounterBenefit",
    "kind": "LinkedField",
    "name": "overTheCounterBenefit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dollarAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "benefitFrequency",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "planInfoFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SupplementalCoverageHistory",
      "kind": "LinkedField",
      "name": "supplementalCoverageHistory",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SupplementalCoverage",
          "kind": "LinkedField",
          "name": "currentCoverage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "medicalCoverage",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AMedicareAdvantagePartDPlan",
                      "kind": "LinkedField",
                      "name": "plan",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "MedicareAdvantagePartDPolicy",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AMedicareAdvantagePlan",
                      "kind": "LinkedField",
                      "name": "plan",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "MedicareAdvantagePolicy",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCartInfoFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOtcShoppingStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "enterUserPolicyFlowFragment"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "6095b7181cd456dc49ca3a8075e319d1";

export default node;
