/**
 * @generated SignedSource<<af9a33da457b151778e347edbfa43ba8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MedicarePlanType = "MEDICARE_ADVANTAGE_PART_D_PLAN" | "MEDICARE_ADVANTAGE_PLAN" | "MEDICARE_PART_D_PLAN" | "MEDICARE_SELECT_PLAN" | "MEDICARE_SUPPLEMENT_PLAN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type medicareAdvantageCoverageFragment$data = {
  readonly supplementalCoverageHistory: {
    readonly currentCoverage: {
      readonly medicalCoverage: {
        readonly id?: string;
        readonly memberId?: string | null;
        readonly membershipStartDate?: string | null;
        readonly plan?: {
          readonly evidenceOfCoverageUrl: string | null;
          readonly medicarePlanType: MedicarePlanType;
          readonly organizationMarketingName: string;
          readonly organizationWebsiteUrl: string | null;
          readonly planName?: string;
          readonly summaryOfBenefitsUrl: string | null;
        };
      };
    };
  };
  readonly " $fragmentType": "medicareAdvantageCoverageFragment";
} | null;
export type medicareAdvantageCoverageFragment$key = {
  readonly " $data"?: medicareAdvantageCoverageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"medicareAdvantageCoverageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memberId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "membershipStartDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "medicarePlanType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationMarketingName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "evidenceOfCoverageUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summaryOfBenefitsUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationWebsiteUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "medicareAdvantageCoverageFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "SupplementalCoverageHistory",
        "kind": "LinkedField",
        "name": "supplementalCoverageHistory",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "SupplementalCoverage",
              "kind": "LinkedField",
              "name": "currentCoverage",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "medicalCoverage",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "kind": "RequiredField",
                            "field": {
                              "alias": null,
                              "args": null,
                              "concreteType": "AMedicareAdvantagePartDPlan",
                              "kind": "LinkedField",
                              "name": "plan",
                              "plural": false,
                              "selections": [
                                (v3/*: any*/),
                                (v4/*: any*/),
                                (v5/*: any*/),
                                (v6/*: any*/),
                                (v7/*: any*/)
                              ],
                              "storageKey": null
                            },
                            "action": "NONE",
                            "path": "supplementalCoverageHistory.currentCoverage.medicalCoverage.plan"
                          }
                        ],
                        "type": "MedicareAdvantagePartDPolicy",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "kind": "RequiredField",
                            "field": {
                              "alias": null,
                              "args": null,
                              "concreteType": "AMedicareAdvantagePlan",
                              "kind": "LinkedField",
                              "name": "plan",
                              "plural": false,
                              "selections": [
                                (v3/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "planName",
                                  "storageKey": null
                                },
                                (v4/*: any*/),
                                (v5/*: any*/),
                                (v6/*: any*/),
                                (v7/*: any*/)
                              ],
                              "storageKey": null
                            },
                            "action": "NONE",
                            "path": "supplementalCoverageHistory.currentCoverage.medicalCoverage.plan"
                          }
                        ],
                        "type": "MedicareAdvantagePolicy",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  "action": "NONE",
                  "path": "supplementalCoverageHistory.currentCoverage.medicalCoverage"
                }
              ],
              "storageKey": null
            },
            "action": "NONE",
            "path": "supplementalCoverageHistory.currentCoverage"
          }
        ],
        "storageKey": null
      },
      "action": "NONE",
      "path": "supplementalCoverageHistory"
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "619edbfd03a3038d10f8327bd5e92b71";

export default node;
