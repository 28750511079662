/**
 * @generated SignedSource<<2be0c725975e8aa1d2037c654f3a3c37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type screenBenefitsQuery$variables = {};
export type screenBenefitsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"descriptionHeaderFragment" | "screenBenefitsContentFragment">;
};
export type screenBenefitsQuery = {
  response: screenBenefitsQuery$data;
  variables: screenBenefitsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OverTheCounterBenefit",
    "kind": "LinkedField",
    "name": "overTheCounterBenefit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dollarAmount",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v0/*: any*/)
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "screenBenefitsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "descriptionHeaderFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "screenBenefitsContentFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "screenBenefitsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalFirstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalLastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ByopState",
                "kind": "LinkedField",
                "name": "byopState",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "carrierOrganizationMarketingName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OverTheCounterCatalog",
                "kind": "LinkedField",
                "name": "resolvedOtcCatalog",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OverTheCounterCartItem",
                "kind": "LinkedField",
                "name": "overTheCounterCartItems",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OverTheCounterItem",
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OverTheCounterCatalog",
                        "kind": "LinkedField",
                        "name": "catalog",
                        "plural": false,
                        "selections": (v1/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "quantity",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SupplementalCoverageHistory",
                "kind": "LinkedField",
                "name": "supplementalCoverageHistory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SupplementalCoverage",
                    "kind": "LinkedField",
                    "name": "currentCoverage",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "medicalCoverage",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AMedicareAdvantagePartDPlan",
                                "kind": "LinkedField",
                                "name": "plan",
                                "plural": false,
                                "selections": (v3/*: any*/),
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "type": "MedicareAdvantagePartDPolicy",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AMedicareAdvantagePlan",
                                "kind": "LinkedField",
                                "name": "plan",
                                "plural": false,
                                "selections": (v3/*: any*/),
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "type": "MedicareAdvantagePolicy",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v1/*: any*/),
                            "type": "MedicareSupplementPolicy",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NightingalePreferences",
                "kind": "LinkedField",
                "name": "nightingalePreferences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shouldShowBenefitsOnboarding",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "BenefitUsageConnection",
                "kind": "LinkedField",
                "name": "benefitUsages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BenefitUsageEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BenefitUsage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "benefitId",
                            "storageKey": null
                          },
                          (v0/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "benefitUsages(first:100)"
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "screen_benefits_benefitUsages",
                "kind": "LinkedHandle",
                "name": "benefitUsages"
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "30705d994f451e7b32c809e96b953c49",
    "id": null,
    "metadata": {},
    "name": "screenBenefitsQuery",
    "operationKind": "query",
    "text": "query screenBenefitsQuery {\n  ...descriptionHeaderFragment\n  ...screenBenefitsContentFragment\n}\n\nfragment cartButtonFragment on Person {\n  ...enterUserPolicyFlowFragment\n  id\n  ...useCartInfoFragment\n  overTheCounterCartItems {\n    quantity\n    item {\n      price\n      id\n    }\n  }\n}\n\nfragment descriptionHeaderFragment on Query {\n  account {\n    person {\n      ...cartButtonFragment\n      id\n    }\n    id\n  }\n}\n\nfragment enterUserPolicyFlowFragment on Person {\n  id\n  legalFirstName\n  legalLastName\n  byopState {\n    carrierOrganizationMarketingName\n  }\n}\n\nfragment screenBenefitsContentFragment on Query {\n  account {\n    id\n    person {\n      id\n      nightingalePreferences {\n        shouldShowBenefitsOnboarding\n      }\n      benefitUsages(first: 100) {\n        edges {\n          node {\n            benefitId\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment useCartInfoFragment on Person {\n  id\n  resolvedOtcCatalog {\n    id\n  }\n  overTheCounterCartItems {\n    item {\n      id\n      price\n      catalog {\n        id\n      }\n    }\n    quantity\n  }\n  supplementalCoverageHistory {\n    currentCoverage {\n      medicalCoverage {\n        __typename\n        ... on MedicareAdvantagePartDPolicy {\n          plan {\n            overTheCounterBenefit {\n              dollarAmount\n            }\n            id\n          }\n          id\n        }\n        ... on MedicareAdvantagePolicy {\n          plan {\n            overTheCounterBenefit {\n              dollarAmount\n            }\n            id\n          }\n          id\n        }\n        ... on MedicareSupplementPolicy {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8f21bd04636dfd765f70bfbc2d9022e";

export default node;
