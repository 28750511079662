/**
 * @generated SignedSource<<809d45cc4fc24536214625a3603839e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type screenBenefitsContentFragment$data = {
  readonly account: {
    readonly id: string;
    readonly person: {
      readonly benefitUsages: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly benefitId: string;
            readonly id: string;
          };
        }>;
      };
      readonly id: string;
      readonly nightingalePreferences: {
        readonly shouldShowBenefitsOnboarding: boolean;
      };
    } | null;
  };
  readonly " $fragmentType": "screenBenefitsContentFragment";
};
export type screenBenefitsContentFragment$key = {
  readonly " $data"?: screenBenefitsContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"screenBenefitsContentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "account",
          "person",
          "benefitUsages"
        ]
      }
    ]
  },
  "name": "screenBenefitsContentFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "NightingalePreferences",
                "kind": "LinkedField",
                "name": "nightingalePreferences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shouldShowBenefitsOnboarding",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "benefitUsages",
                "args": null,
                "concreteType": "BenefitUsageConnection",
                "kind": "LinkedField",
                "name": "__screen_benefits_benefitUsages_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BenefitUsageEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BenefitUsage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "benefitId",
                            "storageKey": null
                          },
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "account"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "28c8118795fcb8228b60b4061b95540d";

export default node;
