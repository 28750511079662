/**
 * @generated SignedSource<<ccfcd641d00a655afc3932cc951c6b78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type cartButtonFragment$data = {
  readonly id: string;
  readonly overTheCounterCartItems: ReadonlyArray<{
    readonly item: {
      readonly price: number;
    };
    readonly quantity: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"enterUserPolicyFlowFragment" | "useCartInfoFragment">;
  readonly " $fragmentType": "cartButtonFragment";
};
export type cartButtonFragment$key = {
  readonly " $data"?: cartButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"cartButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "cartButtonFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "enterUserPolicyFlowFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCartInfoFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterCartItem",
      "kind": "LinkedField",
      "name": "overTheCounterCartItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OverTheCounterItem",
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "99ff8c9e2121c3f47e09c6ac0c183489";

export default node;
