/**
 * @generated SignedSource<<2d70be544e505a5e3a885e1db0347ba3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteOverTheCounterCartItemsInput = {
  itemIds: ReadonlyArray<string>;
  personId: string;
};
export type DeleteCartItemsMutation$variables = {
  input: DeleteOverTheCounterCartItemsInput;
};
export type DeleteCartItemsMutation$data = {
  readonly deleteOtcCartItems: {
    readonly person: {
      readonly id: string;
      readonly overTheCounterCartItems: ReadonlyArray<{
        readonly item: {
          readonly catalog: {
            readonly id: string;
          };
          readonly id: string;
          readonly imageUrl: string | null;
          readonly name: string;
          readonly price: number;
        };
        readonly quantity: number;
      }>;
    };
  };
};
export type DeleteCartItemsMutation = {
  response: DeleteCartItemsMutation$data;
  variables: DeleteCartItemsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteOverTheCounterCartItemsPayload",
    "kind": "LinkedField",
    "name": "deleteOtcCartItems",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OverTheCounterCartItem",
            "kind": "LinkedField",
            "name": "overTheCounterCartItems",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OverTheCounterItem",
                "kind": "LinkedField",
                "name": "item",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OverTheCounterCatalog",
                    "kind": "LinkedField",
                    "name": "catalog",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteCartItemsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteCartItemsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "192aecf65f5580efebacf06deb3b7d0b",
    "id": null,
    "metadata": {},
    "name": "DeleteCartItemsMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteCartItemsMutation(\n  $input: DeleteOverTheCounterCartItemsInput!\n) {\n  deleteOtcCartItems(input: $input) {\n    person {\n      id\n      overTheCounterCartItems {\n        item {\n          id\n          name\n          price\n          imageUrl\n          catalog {\n            id\n          }\n        }\n        quantity\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "14be42ed53e06cdc7cabe75e30a02017";

export default node;
