/**
 * @generated SignedSource<<2d677bdfcf69dc59d1594805d8a450e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type cartItemFragment$data = {
  readonly overTheCounterCartItems: ReadonlyArray<{
    readonly item: {
      readonly catalog: {
        readonly id: string;
      };
      readonly id: string;
      readonly imageUrl: string | null;
      readonly name: string;
      readonly price: number;
    };
    readonly quantity: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"cartItemButtonFragment">;
  readonly " $fragmentType": "cartItemFragment";
};
export type cartItemFragment$key = {
  readonly " $data"?: cartItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"cartItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "cartItemFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "cartItemButtonFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterCartItem",
      "kind": "LinkedField",
      "name": "overTheCounterCartItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OverTheCounterItem",
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OverTheCounterCatalog",
              "kind": "LinkedField",
              "name": "catalog",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "d3469b60fc154194603fa1e9eb015806";

export default node;
