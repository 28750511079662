/**
 * @generated SignedSource<<685cfce9e708f9119c08c8e974fabf5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Sex = "FEMALE" | "MALE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type personalInfoFlowFragment$data = {
  readonly birthDate: string | null;
  readonly id: string;
  readonly legalFirstName: string | null;
  readonly legalLastName: string | null;
  readonly sex: Sex | null;
  readonly " $fragmentType": "personalInfoFlowFragment";
};
export type personalInfoFlowFragment$key = {
  readonly " $data"?: personalInfoFlowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"personalInfoFlowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "personalInfoFlowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalLastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sex",
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "0338f197206562939658128d7c98d3b0";

export default node;
