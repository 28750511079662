/**
 * @generated SignedSource<<ae157865b8a8fd987a5ce10c290cc3e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type orderProgressQuery$variables = {};
export type orderProgressQuery$data = {
  readonly account: {
    readonly person: {
      readonly id: string;
    };
    readonly phoneNumber: any | null;
  };
} | null;
export type orderProgressQuery = {
  response: orderProgressQuery$data;
  variables: orderProgressQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "orderProgressQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "ChapterAccount",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "RequiredField",
              "field": (v2/*: any*/),
              "action": "NONE",
              "path": "account.person"
            }
          ],
          "storageKey": null
        },
        "action": "NONE",
        "path": "account"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "orderProgressQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bb127c677fe86d7f19c8ac774a43f8af",
    "id": null,
    "metadata": {},
    "name": "orderProgressQuery",
    "operationKind": "query",
    "text": "query orderProgressQuery {\n  account {\n    phoneNumber\n    person {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ab8204144ce3d518660ec8774129369";

export default node;
