/**
 * @generated SignedSource<<855d0a3396ccfaa32d43c715e3179e9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type medicarePartDWalletCardFragment$data = {
  readonly currentCoverage: {
    readonly drugCoverage: {
      readonly membershipStartDate?: string | null;
      readonly plan?: {
        readonly organizationMarketingName: string;
        readonly planName: string;
      };
      readonly planId?: string | null;
    };
  };
  readonly " $fragmentType": "medicarePartDWalletCardFragment";
} | null;
export type medicarePartDWalletCardFragment$key = {
  readonly " $data"?: medicarePartDWalletCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"medicarePartDWalletCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "medicarePartDWalletCardFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "SupplementalCoverage",
        "kind": "LinkedField",
        "name": "currentCoverage",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "drugCoverage",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "membershipStartDate",
                      "storageKey": null
                    },
                    {
                      "kind": "RequiredField",
                      "field": {
                        "alias": null,
                        "args": null,
                        "concreteType": "APartDPlan",
                        "kind": "LinkedField",
                        "name": "plan",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "planName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "organizationMarketingName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      "action": "NONE",
                      "path": "currentCoverage.drugCoverage.plan"
                    }
                  ],
                  "type": "MedicarePartDPolicy",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            "action": "NONE",
            "path": "currentCoverage.drugCoverage"
          }
        ],
        "storageKey": null
      },
      "action": "NONE",
      "path": "currentCoverage"
    }
  ],
  "type": "SupplementalCoverageHistory",
  "abstractKey": null
};

(node as any).hash = "44f1e694d3a075fe0a51168ab1c1ad39";

export default node;
