/**
 * @generated SignedSource<<b47c726f048d0bf24a9e33d0eebfc625>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type orderSummaryOrderFragment$data = {
  readonly cancellation: {
    readonly canceledAt: string;
  } | null;
  readonly deliveredAt: string | null;
  readonly items: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly item: {
          readonly price: number;
        };
        readonly quantity: number;
      };
    }> | null;
  };
  readonly placedAt: string;
  readonly " $fragmentType": "orderSummaryOrderFragment";
};
export type orderSummaryOrderFragment$key = {
  readonly " $data"?: orderSummaryOrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"orderSummaryOrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "orderSummaryOrderFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 50
        }
      ],
      "concreteType": "OverTheCounterOrderItemConnection",
      "kind": "LinkedField",
      "name": "items",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OverTheCounterOrderItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OverTheCounterOrderItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OverTheCounterItem",
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "price",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "items(first:50)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deliveredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterOrderCancellation",
      "kind": "LinkedField",
      "name": "cancellation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canceledAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OverTheCounterOrder",
  "abstractKey": null
};

(node as any).hash = "839b6e72bf400ec1a434a644afaf19e2";

export default node;
