/**
 * @generated SignedSource<<91cf01481bffc5076a4969d7ab063e97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type otcDetailsLayoutFragment$data = {
  readonly person: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"HeaderFragment">;
  } | null;
  readonly " $fragmentType": "otcDetailsLayoutFragment";
};
export type otcDetailsLayoutFragment$key = {
  readonly " $data"?: otcDetailsLayoutFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"otcDetailsLayoutFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "otcDetailsLayoutFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeaderFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ChapterAccount",
  "abstractKey": null
};

(node as any).hash = "6129add00a892ebdc4fa289e38933c8f";

export default node;
