/**
 * @generated SignedSource<<e9070499136795ce815eda2b0280f61a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type screen_detailsBenefitQuery$variables = {
  benefitId: string;
};
export type screen_detailsBenefitQuery$data = {
  readonly account: {
    readonly id: string;
    readonly person: {
      readonly benefitUsages: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly benefitId: string;
            readonly id: string;
          };
        }>;
      };
      readonly id: string;
    } | null;
  };
};
export type screen_detailsBenefitQuery = {
  response: screen_detailsBenefitQuery$data;
  variables: screen_detailsBenefitQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "benefitId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "fields": [
    {
      "items": [
        {
          "kind": "Variable",
          "name": "benefitIds.0",
          "variableName": "benefitId"
        }
      ],
      "kind": "ListValue",
      "name": "benefitIds"
    }
  ],
  "kind": "ObjectValue",
  "name": "filter"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BenefitUsageEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BenefitUsage",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "benefitId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "screen_detailsBenefitQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "ChapterAccount",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": "benefitUsages",
                  "args": [
                    (v2/*: any*/)
                  ],
                  "concreteType": "BenefitUsageConnection",
                  "kind": "LinkedField",
                  "name": "__screen_details_benefitUsages_connection",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "account"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "screen_detailsBenefitQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "BenefitUsageConnection",
                "kind": "LinkedField",
                "name": "benefitUsages",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "filter"
                ],
                "handle": "connection",
                "key": "screen_details_benefitUsages",
                "kind": "LinkedHandle",
                "name": "benefitUsages"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "48f240cf7ba7ff9e6f76c52e05c88ce5",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "account",
            "person",
            "benefitUsages"
          ]
        }
      ]
    },
    "name": "screen_detailsBenefitQuery",
    "operationKind": "query",
    "text": "query screen_detailsBenefitQuery(\n  $benefitId: String!\n) {\n  account {\n    id\n    person {\n      id\n      benefitUsages(first: 1, filter: {benefitIds: [$benefitId]}) {\n        edges {\n          node {\n            id\n            benefitId\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b4b4833d3fad0cb78f2799d915ae86b";

export default node;
