/**
 * @generated SignedSource<<4c48b22db3a35164eee22c655cb928d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type screen_originalMedicareInfoQuery$variables = {};
export type screen_originalMedicareInfoQuery$data = {
  readonly account: {
    readonly person: {
      readonly " $fragmentSpreads": FragmentRefs<"medicareInfoFormFragment">;
    } | null;
  } | null;
};
export type screen_originalMedicareInfoQuery = {
  response: screen_originalMedicareInfoQuery$data;
  variables: screen_originalMedicareInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "screen_originalMedicareInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "medicareInfoFormFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "screen_originalMedicareInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalFirstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalLastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MedicareProfile",
                "kind": "LinkedField",
                "name": "medicareProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "medicareBeneficiaryId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "partAEffectiveDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "partBEffectiveDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e8a89d3073919e7d134e101d7b9ec022",
    "id": null,
    "metadata": {},
    "name": "screen_originalMedicareInfoQuery",
    "operationKind": "query",
    "text": "query screen_originalMedicareInfoQuery {\n  account {\n    person {\n      ...medicareInfoFormFragment\n      id\n    }\n    id\n  }\n}\n\nfragment medicareInfoFormFragment on Person {\n  id\n  legalFirstName\n  legalLastName\n  medicareProfile {\n    medicareBeneficiaryId\n    partAEffectiveDate\n    partBEffectiveDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "f3178da80d3e365ef9374bb165f2e9e9";

export default node;
