/**
 * @generated SignedSource<<54d876528162d6d84e1af688d93f54b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpsertBenefitUsageInput = {
  benefitId: string;
  personId: string;
};
export type screen_detailsBenefitVisitedBenefitMutation$variables = {
  connections: ReadonlyArray<string>;
  input: UpsertBenefitUsageInput;
};
export type screen_detailsBenefitVisitedBenefitMutation$data = {
  readonly upsertBenefitUsage: {
    readonly benefitUsage: {
      readonly benefitId: string;
      readonly id: string;
    };
  };
};
export type screen_detailsBenefitVisitedBenefitMutation = {
  response: screen_detailsBenefitVisitedBenefitMutation$data;
  variables: screen_detailsBenefitVisitedBenefitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "BenefitUsage",
  "kind": "LinkedField",
  "name": "benefitUsage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "benefitId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "screen_detailsBenefitVisitedBenefitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertBenefitUsagePayload",
        "kind": "LinkedField",
        "name": "upsertBenefitUsage",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "screen_detailsBenefitVisitedBenefitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertBenefitUsagePayload",
        "kind": "LinkedField",
        "name": "upsertBenefitUsage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "benefitUsage",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "BenefitUsageEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da09ed78100511ad2f677ffdc6a254e7",
    "id": null,
    "metadata": {},
    "name": "screen_detailsBenefitVisitedBenefitMutation",
    "operationKind": "mutation",
    "text": "mutation screen_detailsBenefitVisitedBenefitMutation(\n  $input: UpsertBenefitUsageInput!\n) {\n  upsertBenefitUsage(input: $input) {\n    benefitUsage {\n      id\n      benefitId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f98982e2622c4c60b2d43aebb123e4f4";

export default node;
