import "../styles/globals.css";
// need to import this (or do some stuff with request-animated-frames in webpack)
// for things to build
import "raf/polyfill";
import { Provider } from "@chapter/nightingale-base";
import React from "react";
import Head from "next/head";
import * as FullStory from "@fullstory/browser";
import type { AppProps } from "next/app";
import { buildNightingaleConfiguration } from "../utils";
import { publicEnv } from "@chapter/next-env";

interface MyAppProps extends AppProps {}

const fixReanimatedIssue = () => {
    // FIXME remove this once this reanimated fix gets released
    // https://github.com/software-mansion/react-native-reanimated/issues/3355
    if (typeof window !== "undefined") {
        // @ts-ignore
        window._frameTimestamp = null;
    }
};

fixReanimatedIssue();

const env = publicEnv();
const configuration = buildNightingaleConfiguration(env);

function MyApp({ Component, pageProps }: MyAppProps) {
    React.useEffect(() => {
        if (!FullStory.isInitialized()) {
            // https://help.fullstory.com/hc/en-us/articles/360020622514-Can-Fullstory-capture-content-that-is-presented-in-iframes#h_01F1G333PYKPGZ4B42WDBV3YKV
            // This allows Fullstory to capture content even if this is running in an iframe, which occurs for our benefit
            // widget embed.
            window["_fs_run_in_iframe"] = true;
            // Configuration options: https://github.com/fullstorydev/fullstory-browser-sdk/blob/main/README.md#configuration-options
            FullStory.init({
                orgId: "Y6B5G",
                // recordCrossDomainIFrames should allow us to see the content of an iframe from nightingale-web
                // in the session from a different app (e.g. marketing-site) in FullStory.
                recordCrossDomainIFrames: true,
                devMode: env.NEXT_PUBLIC_APP_ENV !== "production",
            });
        }
    }, []);

    React.useEffect(() => {
        document.body.className = "bg-gray-100";
    });

    return (
        <>
            <Head>
                <title>Chapter</title>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11326250686"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1207639673253926');
fbq('track', 'PageView');

window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'AW-11326250686');
`,
                    }}
                />
                <noscript>
                    <img
                        height="1"
                        width="1"
                        style={{
                            display: "none",
                        }}
                        src="https://www.facebook.com/tr?id=1207639673253926&ev=PageView&noscript=1"
                    />
                </noscript>
            </Head>
            <link rel="stylesheet" href={"https://use.typekit.net/tgh0dvw.css"} />
            <Provider config={configuration}>
                <Component {...pageProps} />
            </Provider>
        </>
    );
}

export default MyApp;
