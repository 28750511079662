export type CustomerReviewContent = {
    name: string;
    quote: string;
    year: string;
};

export const RobertReview: CustomerReviewContent = {
    name: "Robert",
    quote: "Before Chapter, I wasn't sure what my Medicare plan covered. Claiming benefits from my plan was confusing too. Chapter makes it simple and saves me time and money every month.",
    year: "2023",
};

export const CarmenReview: CustomerReviewContent = {
    name: "Carmen",
    quote: "I no longer feel like I'm wasting my OTC credits. They always expired before I could figure out how to use them. The app makes it easy to finally get supplements and first-aid items for free.",
    year: "2023",
};

export const AliciaReview: CustomerReviewContent = {
    name: "Alicia",
    quote: "I didn't realize my Medicare plan came with $150 a quarter to spend on health items. This is a huge help.",
    year: "2023",
};

export const WendySReview: CustomerReviewContent = {
    quote: "Now that I have Medicare, I can finally book of all the doctor's appointments I was afraid to book on my old insurance. Chapter helped me find great doctors that take my plan.",
    name: "Wendy S.",
    year: "2023",
};

export const DebraBReview: CustomerReviewContent = {
    quote: "I called 8 doctors in my insurance directory and none of them had available appointments. I don't know how Chapter did it, but I now have an in-network appointment for next week.",
    name: "Debra B.",
    year: "2023",
};

export const BethanyMReview: CustomerReviewContent = {
    quote: "I'm never logging into my insurance portal again.",
    name: "Bethany M.",
    year: "2023",
};
