/**
 * @generated SignedSource<<a8e63d989dcf022e9f7df11252ff8012>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type itemPersonFragment$data = {
  readonly id: string;
  readonly overTheCounterCartItems: ReadonlyArray<{
    readonly item: {
      readonly catalog: {
        readonly id: string;
      };
      readonly id: string;
      readonly imageUrl: string | null;
      readonly name: string;
      readonly price: number;
    };
    readonly quantity: number;
  }>;
  readonly resolvedOtcCatalog: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"cartItemButtonFragment">;
  readonly " $fragmentType": "itemPersonFragment";
};
export type itemPersonFragment$key = {
  readonly " $data"?: itemPersonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"itemPersonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "itemPersonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "cartItemButtonFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterCatalog",
      "kind": "LinkedField",
      "name": "resolvedOtcCatalog",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterCartItem",
      "kind": "LinkedField",
      "name": "overTheCounterCartItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OverTheCounterItem",
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OverTheCounterCatalog",
              "kind": "LinkedField",
              "name": "catalog",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "5a80ab8045232061419934e6d21ce201";

export default node;
