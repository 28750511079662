/**
 * @generated SignedSource<<c4f333581d8647096e7a612372dac9ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type medicareInfoModalFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"medicareInfoFormFragment">;
  readonly " $fragmentType": "medicareInfoModalFragment";
};
export type medicareInfoModalFragment$key = {
  readonly " $data"?: medicareInfoModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"medicareInfoModalFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "medicareInfoModalFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "medicareInfoFormFragment"
    }
  ],
  "type": "Person",
  "abstractKey": null
};

(node as any).hash = "4c8bd4d9667af652256db821bb986f2a";

export default node;
