/**
 * @generated SignedSource<<9cae884735b49ea07c0ee61c1303bc23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Frequency = "ANNUALLY" | "EVERY_THREE_YEARS" | "EVERY_TWO_YEARS" | "MONTHLY" | "QUARTERLY" | "TWICE_A_YEAR" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useOtcShoppingStateFragment$data = {
  readonly byopState: {
    readonly carrierOrganizationMarketingName: string | null;
  };
  readonly resolvedOtcCatalog: {
    readonly id: string;
  } | null;
  readonly supplementalCoverageHistory: {
    readonly currentCoverage: {
      readonly medicalCoverage: {
        readonly __typename: "MedicareAdvantagePartDPolicy";
        readonly plan: {
          readonly organizationMarketingName: string;
          readonly overTheCounterBenefit: {
            readonly benefitFrequency: Frequency;
            readonly dollarAmount: number;
          } | null;
          readonly planName: string;
        };
      } | {
        readonly __typename: "MedicareAdvantagePolicy";
        readonly plan: {
          readonly organizationMarketingName: string;
          readonly overTheCounterBenefit: {
            readonly benefitFrequency: Frequency;
            readonly dollarAmount: number;
          } | null;
          readonly planName: string;
        };
      } | {
        readonly __typename: "MedicareSupplementPolicy";
        readonly medicareSupplementPlan: {
          readonly carrierName: string;
          readonly planName: string;
        };
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
    };
  } | null;
  readonly " $fragmentType": "useOtcShoppingStateFragment";
};
export type useOtcShoppingStateFragment$key = {
  readonly " $data"?: useOtcShoppingStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useOtcShoppingStateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planName",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "organizationMarketingName",
    "storageKey": null
  },
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "OverTheCounterBenefit",
    "kind": "LinkedField",
    "name": "overTheCounterBenefit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dollarAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "benefitFrequency",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useOtcShoppingStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SupplementalCoverageHistory",
      "kind": "LinkedField",
      "name": "supplementalCoverageHistory",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "concreteType": "SupplementalCoverage",
            "kind": "LinkedField",
            "name": "currentCoverage",
            "plural": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "medicalCoverage",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AMedicareAdvantagePartDPlan",
                          "kind": "LinkedField",
                          "name": "plan",
                          "plural": false,
                          "selections": (v2/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "type": "MedicareAdvantagePartDPolicy",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AMedicareAdvantagePlan",
                          "kind": "LinkedField",
                          "name": "plan",
                          "plural": false,
                          "selections": (v2/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "type": "MedicareAdvantagePolicy",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AMedicareSupplementPlan",
                          "kind": "LinkedField",
                          "name": "medicareSupplementPlan",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "carrierName",
                              "storageKey": null
                            },
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "MedicareSupplementPolicy",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                "action": "NONE",
                "path": "supplementalCoverageHistory.currentCoverage.medicalCoverage"
              }
            ],
            "storageKey": null
          },
          "action": "NONE",
          "path": "supplementalCoverageHistory.currentCoverage"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ByopState",
      "kind": "LinkedField",
      "name": "byopState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "carrierOrganizationMarketingName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OverTheCounterCatalog",
      "kind": "LinkedField",
      "name": "resolvedOtcCatalog",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Person",
  "abstractKey": null
};
})();

(node as any).hash = "8f67a4990ea7672dd3a5d85ecba67daa";

export default node;
