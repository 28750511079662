/**
 * @generated SignedSource<<d079695ac79639e10afe90658f7cc684>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type screenAccountDeletionQuery$variables = {};
export type screenAccountDeletionQuery$data = {
  readonly account: {
    readonly person: {
      readonly id: string;
    };
  };
};
export type screenAccountDeletionQuery = {
  response: screenAccountDeletionQuery$data;
  variables: screenAccountDeletionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "screenAccountDeletionQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "ChapterAccount",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": (v1/*: any*/),
              "action": "THROW",
              "path": "account.person"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "account"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "screenAccountDeletionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChapterAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "adaaa786f1f6103197f658d42889b4ba",
    "id": null,
    "metadata": {},
    "name": "screenAccountDeletionQuery",
    "operationKind": "query",
    "text": "query screenAccountDeletionQuery {\n  account {\n    person {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e52f088816b3a05471ac436322a13d5e";

export default node;
